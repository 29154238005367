import * as actions from './globalSlice';
import * as documentsApis from '~api/documents';
import * as documentConstants from '~constants/documents';
import * as authActions from '~features/auth/actions';

export const setTermsAndConditions = params => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      documentsApis
        .getDocumentByType({ type: documentConstants.TERMS_AND_CONDITIONS })
        .then(res => {
          dispatch(actions.setTermsAndConditions(res));
          resolve(res);
        });
    });
  };
};

export const setPrivacyAndPolicy = () => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      documentsApis.getDocumentByType({ type: documentConstants.PRIVACY_AND_POLICY }).then(res => {
        dispatch(actions.setPrivacyAndPolicy(res));
        resolve(res);
      });
    });
  };
};

export const hideMenu = () => {
  return dispatch => {
    dispatch(actions.hideMenu());
  };
};

export const showMenu = () => {
  return dispatch => {
    dispatch(actions.showMenu());
  };
};

export const showForgotPassModal = () => {
  return dispatch => {
    dispatch(actions.showForgotPassModal());
    dispatch(authActions.setIsShowLogin(false));
  };
};

export const showLoginModal = param => {
  return dispatch => {
    dispatch(actions.showLoginModal(param));
    dispatch(authActions.setIsShowLogin(true));
  };
};
export const showRegisterModal = () => {
  return dispatch => {
    dispatch(actions.showRegisterModal());
    dispatch(authActions.setIsShowLogin(false));
  };
};

export const hideLoginModalByKey = key => {
  return dispatch => {
    dispatch(actions.hideLoginModalByKey(key));
    if (key === 'showLoginModal') {
      dispatch(authActions.setIsShowLogin(false));
    }
  };
};

export const showLoginModalByKey = key => {
  return dispatch => {
    dispatch(actions.showLoginModalByKey(key));
    if (key === 'showLoginModal') {
      dispatch(authActions.setIsShowLogin(true));
    }
  };
};

export function setOpenRequestSignIn(value) {
  return dispatch => dispatch(actions.setOpenRequestSignIn(value));
}
