import * as appAPIs from '../../api/appAPIs';
import { store } from '../../store/index';
import { setNewState } from './blockingSlice';
import moment from 'moment';
import { backOff } from 'exponential-backoff';

const checkBlockCountry = () => {
  return async dispatch => {
    const { loaded, isBlock, expire, duration } = store.getState().blockCountry;
    const now = moment().unix();

    // if (loaded && expire >= now) {
    if (loaded) {
      if (!isBlock) {
        return Promise.resolve();
      }
      return Promise.reject();
    }

    const options = {
      numOfAttemps: 10,
      timeMultiple: 2,
      startingDelay: 2000,
    };

    const response = await backOff(() => appAPIs.allowedCountries(), options);
    // process response
    const { isBlock: notAllow, result } = response;

    return new Promise((resolve, reject) => {
      if (!notAllow) {
        dispatch(
          setNewState({
            loaded: true,
            loading: false,
            isBlock: false,
            expire: now + duration,
          }),
        );
        resolve(result);
      } else {
        dispatch(
          setNewState({
            loaded: true,
            loading: false,
            isBlock: true,
          }),
        );
        reject(new Error('Block country'));
      }
    });
  };
};
export { checkBlockCountry };
