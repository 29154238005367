import { createSlice } from '@reduxjs/toolkit';

const globalSlice = createSlice({
  name: 'global',
  initialState: {
    documents: {
      termsAndConditions: {},
      privacyAndPolicy: {},
    },
    menu: {
      isHidden: false,
    },
    loginForm: {
      showLoginModal: false,
      showRegisterModal: false,
      showResetPasswordModal: false,
      showMessagePopup: false,
      showMessagePopup: false,
      param: null,
    },
    openRequestSignIn: false,
  },

  reducers: {
    setTermsAndConditions(state, action) {
      state.documents.termsAndConditions = action.payload;
    },
    setPrivacyAndPolicy(state, action) {
      state.documents.privacyAndPolicy = action.payload;
    },
    hideMenu(state, action) {
      state.menu.isHidden = true;
    },
    showMenu(state, action) {
      state.menu.isHidden = false;
    },
    showForgotPassModal: state => {
      state.loginForm.showLoginModal = false;
      state.loginForm.showRegisterModal = false;
      state.loginForm.showResetPasswordModal = true;
      state.loginForm.showMessagePopup = false;
    },
    showLoginModal: (state, action) => {
      state.loginForm.showLoginModal = true;
      state.loginForm.showRegisterModal = false;
      state.loginForm.showResetPasswordModal = false;
      state.loginForm.showMessagePopup = false;
      state.loginForm.param = action?.payload;
    },
    showRegisterModal: (state, action) => {
      state.loginForm.showLoginModal = false;
      state.loginForm.showRegisterModal = false;
      state.loginForm.showResetPasswordModal = true;
      state.loginForm.showMessagePopup = false;
    },
    hideLoginModalByKey: (state, action) => {
      const { payload: key } = action;
      state.loginForm[key] = false;
    },
    showLoginModalByKey: (state, action) => {
      const { payload: key } = action;
      state.loginForm[key] = true;
    },
    setOpenRequestSignIn: (state, action) => {
      state.openRequestSignIn = action.payload;
    },
  },
});

export const {
  setTermsAndConditions,
  setPrivacyAndPolicy,
  hideMenu,
  showMenu,
  showForgotPassModal,
  showLoginModal,
  showRegisterModal,
  hideLoginModalByKey,
  showLoginModalByKey,
  setOpenRequestSignIn,
} = globalSlice.actions;

export const openRequestSignInSelector = state => state.global.openRequestSignIn;

export default globalSlice.reducer;
