export const MOVIE_TYPE = 1;
export const SHOW_TYPE = 2;
export const SEASON_TYPE = 3;
export const EPISODE_TYPE = 4;
export const CHANNEL_TYPE = 5;
export const CLIP = 6;
export const CURATED_LIVE = 7;
export const LIVE_EVENT = 9;

export const ALL_LIVE_TYPE = [CURATED_LIVE, CHANNEL_TYPE, LIVE_EVENT];

export const TYPE_NO_LANDING_PAGE = [...ALL_LIVE_TYPE];
