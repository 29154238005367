import { SHOW_TYPE } from '~core/constants/contentConstants';
import i18n from 'i18next';
import qs from 'query-string';
import {
  subscriptionContent,
  subscriptionAccount,
  renderBadgeContent,
  checkSubscriptionContentTheHighest,
} from '~core/method/subScriptionPlanMethod';
import _ from 'lodash';
import videojs from 'video.js';
import { getConfigByKey } from '~features/tenantConfig/services';
import { AGE_RATING, CONTENT_RATING } from '~core/constants/ageContentRating';
import moment from 'moment';

export function getLinkUpgradeToWatch() {
  try {
    const flagEnbTier = getConfigByKey('features.multiTierSubscriptionEnabled');
    const { tierPackage, entity } = this;
    const { tier_subscription = 1, type, slug, id, min_sub_tier } = entity;
    const lng = i18n.language;
    const callbackUrl = type === SHOW_TYPE ? `${lng}/landing/${slug}` : `${lng}/detail/${slug}`;
    return `/${lng}/box/offers?${qs.stringify({
      callbackUrl,
      tier: flagEnbTier ? tierPackage : null,
    })}`;
  } catch (error) {
    return '';
  }
}

export function needUpgrade(account, inListing = true) {
  try {
    const { is_watchable, min_sub_tier, can_preview, is_premium, has_free_content } = this;
    if (inListing && has_free_content) {
      return false;
    }

    if (is_watchable || can_preview || !is_premium) {
      return false;
    }
    const { profile = {} } = account || {};
    if (_.isEmpty(profile)) {
      return true;
    }
    const {
      subscription_plan_info: { sub_tier },
      has_subscription,
    } = profile || {};
    if (!has_subscription) {
      return true;
    }

    if (min_sub_tier && sub_tier && min_sub_tier?.tier < sub_tier.tier) {
      return true;
    }
    return false;
  } catch (error) {
    return true;
  }
}

export function checkBadge() {
  try {
    const { account, entityDetail } = this;
    const { profile = {} } = account;
    const { subscription_plan_info = {} } = !_.isEmpty(profile) && profile;
    if (_.isEmpty(subscription_plan_info)) {
      return renderBadgeContent.call(entityDetail);
    }
    if (checkSubscriptionContentTheHighest.call({ entityDetail, subscription_plan_info })) {
      return renderBadgeContent.call(entityDetail);
    }
    return '';
  } catch (err) {
    return '';
  }
}

export function renderBadge() {
  try {
    const { account, entityDetail } = this;
    if (!account) {
      return renderBadgeContent.call(entityDetail);
    }
    return checkBadge.call(this);
  } catch (error) {}
}

export function getDrmLinkPlay() {
  const self = this;

  try {
    const { link_play, play_info = {}, drm_session_info, type } = self;
    const { dash_link_play, hls_link_play } = play_info.data || {};
    if (_.isEmpty(drm_session_info)) {
      return link_play;
    }

    if (isHLS()) {
      return hls_link_play;
    }
    return dash_link_play;
  } catch (error) {}
  return '';
}

export function isHLS() {
  const { IS_ANY_SAFARI, IS_IOS, IS_IPAD } = videojs.browser;
  const hlsSupport = [IS_ANY_SAFARI, IS_IOS, IS_IPAD];
  return hlsSupport.includes(true);
}

export function formatUnitOfferCount() {
  const { unit_count = 0, offer_unit_count = 0, text_unit } = this;
  try {
    return `${unit_count >= 1 ? `${unit_count} ` : ''}${text_unit}${
      offer_unit_count > 1 ? ` + ${offer_unit_count} ${text_unit}` : ''
    }`;
  } catch (error) {
    return '';
  }
}

export function getAgeContentRating() {
  const limitAge = 0;
  try {
    const {
      metadata: { content_ratings },
    } = this;
    const ageRating = (content_ratings || []).filter(
      rating => rating.type === AGE_RATING && (rating?.min_age ?? -1) >= limitAge,
    );
    return ageRating.length > 0 ? ageRating[0] : null;
  } catch (error) {
    return null;
  }
}

export function getContentRatingList() {
  const limitAge = 0;
  try {
    const {
      metadata: { content_ratings },
    } = this;
    const contentRatings = (content_ratings || []).filter(rating => rating.type === CONTENT_RATING);
    return contentRatings;
  } catch (error) {
    return [];
  }
}

export function getProgressHasCache(progressCache) {
  const { progress, id } = this;

  try {
    const progressCacheById = progressCache[id];

    const { value, expireTime } = progressCacheById || {};
    if ((value || 0) > 0 && expireTime > new Date().getTime()) {
      return value;
    }
    return progress;
  } catch (error) {
    return progress;
  }
}

export function isDVRRewatchEnabled() {
  try {
    return !!this?.license_period?.dvr_rewatch_enabled;
  } catch (error) {}
  return false;
}

export function isBeforeLiveEvent(nowMomentInstance = null) {
  try {
    const { start_on } = this;
    const now = nowMomentInstance || moment();
    return now.isBefore(moment(start_on));
  } catch (error) {
    return false;
  }
}

export function isEndLiveEvent(nowMomentInstance = null) {
  try {
    const { start_on, duration, unpublished_time } = this;
    const now = nowMomentInstance || moment();

    return (
      now.isAfter(moment(unpublished_time)) || now.isAfter(moment(start_on).add(duration, 'second'))
    );
  } catch (error) {
    return false;
  }
}

export function activeLiveEvent(nowMomentInstance = null) {
  try {
    const now = nowMomentInstance || moment();

    return !(isBeforeLiveEvent.call(this, now) || isEndLiveEvent.call(this, now));
  } catch (error) {
    return false;
  }
}

export function getCategoryName() {
  try {
    const { content_categories } = this;
    if (content_categories.length > 0) {
      return content_categories[0].name;
    }
    return '';
  } catch (error) {
    return '';
  }
}

export function getTrailers() {
  const self = this;

  try {
    return self.trailers || [];
  } catch (error) {}
  return [];
}

export function getFirstTrailer() {
  const self = this;

  try {
    const trailers = getTrailers.call(self);
    return trailers[0];
  } catch (error) {}
  return null;
}
