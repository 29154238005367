import { getConfigByKey } from '~features/tenantConfig/services';
import jquery from 'jquery';

const insertScript = tenantConfigs => {
  if (window.ir) {
    return;
  }
  if (!getConfigByKey('feature_advanced_web_multitenancy_enabled')) {
    return;
  }
  const body = jquery('body');
  let html = '';
  if (getConfigByKey('features.cookielaw')) {
    html += `
      <!-- OneTrust Cookies Consent Notice start -->
      <script
        defer
        src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
        type="text/javascript"
        charset="UTF-8"
        data-domain-script="${getConfigByKey('integrations.cookielaw.data_domain_script')}"
      ></script>
      <script type="text/javascript">
        function OptanonWrapper() {}
      </script>
      <!-- OneTrust Cookies Consent Notice end -->
    `;
  }
  if (getConfigByKey('features.invite_referrals')) {
    const { bid_e, bid } = getConfigByKey('integrations.invite_referrals');
    html += `
      <div id='invtrflfloatbtn'></div>
      <script>
        var ir = ir || function () { (window.ir.q = window.ir.q || []).push(arguments) };
        var invite_referrals = window.invite_referrals || {}; (function () {
          invite_referrals.auth = {
            bid_e: "${bid_e}",
            bid: "${bid}",
            t: '420',
            email: '', userParams: { 'fname': '' }
          };
          invite_referrals.async = false;
          var script = document.createElement('script');
          script.defer = true;
          script.src = "//cdn.invitereferrals.com/js/invite-referrals-1.0.js";
          var entry = document.getElementsByTagName('script')[0]; entry.parentNode.insertBefore(script, entry);
        })();
      </script>
    `;
  }
  body.append(html);
};

export { insertScript };
