import { store } from '../store/index';
import moment from 'moment';
import { decodeToken } from 'react-jwt';
import { startFetch } from './fetch';
import { resetActiveIDs } from '../components/filter/filterSlice';
import { setIsShowLogin, logout } from '../features/auth/actions';
import { history } from '../store/history';

const REFRESH_TOKEN_EXPIRED = 'REFRESH_TOKEN_EXPIRED';
const REFRESH_TOKEN_INVALID = 'REFRESH_TOKEN_INVALID';
const AUTHENTICATION_FAILED = 'authentication_failed';
let buffers = [];
let isRefreshing = false;

function addBuffer(buffer) {
  buffers.push(buffer);
}

function resetBuffers() {
  buffers = [];
}

function getBuffers() {
  return buffers;
}

function setIsRefreshing(value) {
  isRefreshing = value;
}

function getIsRefreshing() {
  return isRefreshing;
}

function requestRefreshToken() {
  const auth = store.getState().auth;
  const refreshToken = auth.account && auth.account.refresh_token ? auth.account.refresh_token : '';
  const path = '/backend/cas/refresh/';
  const headers = {
    Authorization: refreshToken,
  };
  return new Promise((resolve, reject) => {
    const dispatch = store.dispatch;
    startFetch(path, 'POST', headers, {})
      .then(response => {
        dispatch({ type: 'LOGIN_SUCCESS', account: response });
        resolve(response);
      })
      .catch(error => {
        reject(error);
      });
  });
}

function isRequestRefreshToken() {
  const auth = store.getState().auth;
  if (auth && !auth.isLogin) {
    return false;
  }
  const durationTime = 60 * 5;
  const timestamp = moment().unix();
  const account = auth.account;
  const dcToken = decodeToken(account.access_token || '');
  const exp = dcToken.exp || 0;
  const restExpireTime = exp - timestamp;
  if (!(restExpireTime < durationTime)) {
    return false;
  }
  return true;
}

function doneRefreshToken(response, error) {
  const buffers = getBuffers();
  const isRefreshFailed = !!(
    response === null &&
    error &&
    (error.error_code === REFRESH_TOKEN_EXPIRED ||
      error.error_code === REFRESH_TOKEN_INVALID ||
      error.error_code === AUTHENTICATION_FAILED)
  );
  if (isRefreshFailed) {
    const dispatch = store.dispatch;
    dispatch(logout());
    dispatch(resetActiveIDs());
    dispatch(setIsShowLogin(true));
    history.push('/');
    // setTimeout(() => {
    //   window.location.reload()
    // }, 1000)
    return;
  }
  buffers.some(buffer => {
    const [fetchApi, options] = buffer;
    if (typeof fetchApi !== 'function') {
      return;
    }

    let opts = { ...options };
    let authorization = '';
    if (!isRefreshFailed) {
      authorization = response && response.access_token ? response.access_token : '';
    }
    opts = {
      ...options,
      headers: {
        ...options.headers,
        Authorization: authorization,
      },
    };
    fetchApi(opts);
  });
  resetBuffers();
  setIsRefreshing(false);
}

function checkRefreshToken(buffer, { path, method, headers, body }) {
  const auth = store.getState().auth;

  if (typeof buffer !== 'function') {
    return;
  }

  const isRequest = isRequestRefreshToken();
  if (auth && auth.isLogin && headers && headers.Authorization) {
    if (isRequest && !isRefreshing) {
      setIsRefreshing(true);
      requestRefreshToken()
        .then(response => {
          doneRefreshToken(response, null);
        })
        .catch(error => {
          doneRefreshToken(null, error);
        });
    }
    if (isRefreshing) {
      addBuffer([buffer, { path, method, headers, body }]);
      return;
    }
  }
  buffer({ path, method, headers, body });
}

export { isRequestRefreshToken, doneRefreshToken, checkRefreshToken };
