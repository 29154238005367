import { createSlice } from '@reduxjs/toolkit';

const historySlice = createSlice({
  name: 'history',
  initialState: {
    previousLocation: null,
  },
  reducers: {
    setPreviousLocation(state, action) {
      const value = action.payload;
      state.previousLocation = value;
    },
  },
});

export const { setPreviousLocation } = historySlice.actions;

export default historySlice.reducer;
