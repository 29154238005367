import * as types from './actionTypes';
import { getConfigByKey } from '~features/tenantConfig/services';

const initialState = {
  menu: [],
  isHomePage: false,
  page: null,
  pageSlug: null,
  isLoadingPage: true,
  isRefreshPage: false,
  ribbonTarget: '',
  removeProcess: {
    isOpen: false,
    items: {},
    removeId: '',
  },
  extraBanners: [],
};

export default function home(state = initialState, action = {}) {
  switch (action.type) {
    case types.GET_MENU:
      return {
        ...state,
        menu: Array.isArray(action.menu) ? action.menu : [{}],
      };
    case types.GET_PAGE:
      let isHomePage = false;
      let bannerExtra = [];
      if (state.menu.length > 0 && action.page && state.menu[0].slug === action.page.pageSlug) {
        isHomePage = true;
      }

      return {
        ...state,
        page: {
          ...action.page,
          banners: [...action.page.banners, ...bannerExtra],
        },
        isLoadingPage: false,
        isHomePage,
        pageSlug: action.page.pageSlug,
      };
    case types.GET_RECENT:
      return {
        ...state,
        page: {
          ...state.page,
          ribbons: [...action.payload, ...state.page.ribbons],
        },
        isLoadingPage: false,
      };
    case types.GET_PAGE_REQUEST:
      return {
        ...state,
        isLoadingPage: true,
      };
    case types.REFRESH_PAGE:
      return {
        ...state,
        isRefreshPage: action.isRefreshPage,
      };
    case types.GET_FINAL:
      return {
        ...state,
        isLoadingPage: false,
      };
    case types.GET_RIBBON_TARGET:
      return {
        ...state,
        ribbonTarget: action.idRibbonTarget,
      };
    case types.UPDATE_STATUS_REMOVE_PROCESS:
      return {
        ...state,
        removeProcess: action.payload,
      };
    case types.UPDATE_SEARCH:
      return {
        ...state,
        page: {
          ...state.page,
          search: action.queryParams,
        },
      };
    case types.UPDATE_PAGE_SLUG:
      return {
        ...state,
        pageSlug: action.payload,
      };
    case types.SET_EXTRA_BANNERS:
      return {
        ...state,
        extraBanners: action.payload,
      };
    default:
      return state;
  }
}
