import * as types from './actionTypes';

const initialState = {
  page: {
    search: '/',
  },
  listRibbonTarget: [],
  filterHistory: [],
};

export default function youtubePage(state = initialState, action = {}) {
  switch (action.type) {
    case types.UPDATE_LIST_RIBBON:
      return {
        ...state,
        listRibbonTarget: action.payload,
      };
    case types.UPDATE_PAGE_YOUTUBE:
      return {
        ...state,
        page: action.payload,
      };
    case types.UPDATE_SEARCH:
      return {
        ...state,
        page: {
          ...state.page,
          search: action.queryParams,
        },
      };
    case types.UPDATE_HISTORY:
      return {
        ...state,
        filterHistory: action.payload,
      };
    default:
      return state;
  }
}
