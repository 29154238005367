import ReactOneSignal from 'react-onesignal';
import * as API_STRING from '../../constants/apiString';
import { getConfigByKey } from '../../features/tenantConfig/services';

const initialize = () => {
  if ((window.OneSignal && window.OneSignal.initialized) || !getConfigByKey('features.onesignal')) {
    return;
  }
  const { app_id, safari_web_id } = getConfigByKey('integrations.onesignal');
  if (!app_id) {
    return;
  }
  const options = {
    // allowLocalhostAsSecureOrigin: true,
    // requiresUserPrivacyConsent: true,
    // autoRegister: false,
    // autoResubscribe: true,
    safari_web_id: safari_web_id,
    notifyButton: {
      enable: true,
    },
  };
  ReactOneSignal.initialize(app_id, options);
  const interval = setInterval(() => {
    if (!ReactOneSignal.initialize || !window.OneSignal) {
      return;
    }
    try {
      window.OneSignal.showSlidedownPrompt();
    } catch (e) {
      console.error('Error while showing the OneSignal notification permission request', e);
    }
    clearInterval(interval);
  }, 2000);
};

const onsignalService = {
  initialize,
};

export default onsignalService;
