// Those environment config is injected dynamically upon /js/tenantConfig.js file rendering.
// And is available statically at runtime of our SPA application.

const getConfigFromEnv = () => window.envConfig || global.envConfig || {};
const {
  tenant_slug,
  platform_slug,
  ui_theme, // Not available on backend yet
  environment,
  ...others
} = getConfigFromEnv();

export const tenantEnvConfig = {
  tenant_slug,
  platform_slug,
  ui_theme, // Not available on backend yet
  environment,
  ...others,
};
