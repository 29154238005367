import React from 'react';
import { Box } from '@material-ui/core';
import { Button, Modal, Row, Col } from 'react-bootstrap';
import styled from 'styled-components';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from './alertPopupSlice';
import clsx from 'clsx';

const PopupStyled = styled(Modal)`
  top: 22%;
  text-align: center;

  .modal-dialog {
    width: auto;
    display: inline-block;
  }
  .modal-content {
    position: relative;
    /* background-image: url(${require('~img/img/sctv/ppv-app-background.png')}); */
    background-repeat: no-repeat;
    background-size: cover;

    &:before {
      content: '';
      display: block;
      /* padding-top: calc(385 / 615 * 100%); */
    }
  }
  &.iap {
    .modal-content {
      &:before {
        /* padding-top: calc(532 / 615 * 100%); */
      }
    }
  }
  .modal-header {
    padding: 0;
    &.require-confirm {
      .close {
        pointer-events: none;
        visibility: hidden;
      }
    }
  }

  .modal-body {
    flex-grow: 1;
    display: flex;
    align-items: center;
    padding: 0;
  }

  .close {
    font-weight: 300;
    color: #121212;
    opacity: 1;
    font-size: 28px;
    line-height: 20px;
    padding: 14px;
  }

  .wrap {
    /* position: absolute; */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
  }
  @media (min-width: 768px) {
    .modal-content {
      min-width: 436px;
      max-width: 500px;
    }
  }
  @media (max-width: 767px) {
    .close {
      padding: 10px;
    }
    .modal-body {
      padding: 0 16px;
    }
    .modal-content {
      &:before {
        /* padding-top: calc(272 / 395 * 100%); */
      }
    }
  }
`;

const AlertModalStyled = styled.div`
  padding: 0 35px 35px;

  .v-alert {
    &--title {
      font-family: Open Sans;
      font-size: 24px;
      color: #283237;
      text-decoration: none solid rgb(40, 50, 55);
      text-align: center;
      margin-top: 10px;
      margin-bottom: 5%;
      font-weight: bold;
      &.error {
        color: #e30000;
      }
    }
    &--description {
      font-family: Open Sans;
      font-size: 16px;
      color: #495963;
      text-decoration: none solid rgb(73, 89, 99);
      text-align: center;
      margin-bottom: 4%;
      /* padding: 0 10%; */
    }
    &--red {
      color: #e72d30;
      font-weight: bold;
    }

    &--btn {
      background-color: #e72d30;
      width: 100%;
      border-radius: 10px;
      font-family: Noto Sans;
      font-size: 20px;
      color: #ffffff;
      text-decoration: none solid rgb(255, 255, 255);
      text-align: center;
      font-weight: bold;
      padding: 15px;
      font-weight: bold;
      margin-right: 7px;
    }
    &--cancel {
      background-color: #8c8c8c;
    }
  }
  .actions {
    padding-top: 12px;
  }
  @media (max-width: 767px) {
    padding: 0 16px 16px;

    .v-alert {
      &--title {
        font-size: 24px;
      }
      &--description {
        font-size: 14px;
        padding: 0;
      }
      &--btn {
        padding: 10px;
      }
    }
  }
`;

const AlertModal = props => {
  const alertPopup = useSelector(state => state.alertPopup);
  const dispatch = useDispatch();

  const closeDefault = () => {
    dispatch(actions.close());
  };
  const {
    handleClose = closeDefault,
    isDisplayCancelButton = false,
    isDisplayOklButton = true,
  } = props;

  return (
    <PopupStyled
      show={alertPopup?.open}
      onHide={handleClose}
      aria-labelledby="contained-modal-title-vcenter"
      className={`v-popupUpgrade`}
      centered
      backdrop={alertPopup?.requireConfirm ? 'static' : true}
    >
      <div className="wrap">
        <Modal.Header
          className={clsx({ 'require-confirm': alertPopup?.requireConfirm })}
          closeButton
          style={{ border: 'none', paddingBottom: 0 }}
        ></Modal.Header>
        <Modal.Body>
          <AlertModalStyled className="v-alert">
            <h2 className={clsx('v-alert--title', { error: alertPopup?.isError })}>
              {alertPopup?.title}
            </h2>
            {alertPopup?.descriptions.map(description => (
              <Box className="v-alert--description">{description}</Box>
            ))}

            <Box className="actions" display={'flex'} justifyContent={'space-between'}>
              {isDisplayOklButton && (
                <Button className="v-alert--btn" onClick={handleClose}>
                  {alertPopup?.txtConfirmButton || 'OK'}
                </Button>
              )}
              {isDisplayCancelButton && (
                <Button className="v-alert--btn cancel" onClick={handleClose}>
                  Hủy
                </Button>
              )}
            </Box>
          </AlertModalStyled>
        </Modal.Body>
      </div>
    </PopupStyled>
  );
};

export default AlertModal;
