if (!window.gtmVar) {
  window.gtmVar = {};
}

export const gtmVar_setIsLoggedIn = value => {
  window.gtmVar.isLoggedIn = value;
};

export const gtmVar_setHasSubscription = value => {
  window.gtmVar.hasSubscription = value;
};
