import firebase from 'firebase/app';
import 'firebase/analytics';
import { getConfigByKey } from '../../features/tenantConfig/services';
import 'firebase/performance';
const initialize = () => {
  if (firebase.apps.length > 0 || !getConfigByKey('features.firebaseFeature')) {
    return Promise.reject();
  }
  let firebaseConfig = getConfigByKey('integration_config.firebase');
  if (firebaseConfig) firebaseConfig = JSON.parse(firebaseConfig.replace(/'/g, '"'));
  return new Promise((resolve, reject) => {
    if (!firebaseConfig || (firebaseConfig && firebaseConfig.project_info)) {
      reject();
    }
    firebase.initializeApp(firebaseConfig);
    firebase.analytics();
    firebase.performance();
    resolve(firebaseConfig);
  });
};
const firebaseService = {
  initialize,
};

export default firebaseService;
export { firebase };
