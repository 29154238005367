export default {
  menuId: {
    accountInformationBtn: 'profile-tab-account-information-btn',
    changeInformationBtn: 'profile-tab-change-information-btn',
    changePasswordBtn: 'profile-tab-change-password-btn',
    availableplanBtn: 'profile-tab-available-plan-btn',
    paymenthistoryBtn: 'profile-tab-payment-history-btn',
    loginCodeBtn: 'profile-tab-login-code-btn',
    contactUsBtn: 'profile-tab-contact-us-btn',
    faqBtn: 'profile-tab-faqs-btn',
  },
  accountInformationTabId: {
    title: 'profile-tab-account-information-title-lbl',
    fullNameLbl: 'profile-tab-account-information-fullname-lbl',
    fullNameTxt: 'profile-tab-account-information-fullname-txt',
    emailLbl: 'profile-tab-account-information-email-lbl',
    emailTxt: 'profile-tab-account-information-email-txt',
    birthdayLbl: 'profile-tab-account-information-birthday-lbl',
    birthdayTxt: 'profile-tab-account-information-birthday-txt',
    phoneNumberLbl: 'profile-tab-account-information-phone-number-lbl',
    phoneNumberTxt: 'profile-tab-account-information-phone-number-txt',
    genderLbl: 'profile-tab-account-information-gender-lbl',
    genderTxt: 'profile-tab-account-information-gender-txt',
    facebookIdLbl: 'profile-tab-account-information-facebook-id-lbl',
    facebookIdTxt: 'profile-tab-account-information-facebook-id-txt',
    updateAccountBtn: 'profile-tab-account-information-update-account-btn',
    updatePlanBtn: 'profile-tab-account-information-update-plan-btn',
    titleLbl: 'profile-tab-account-information-title-lbl',
    planNameTxt: 'profile-tab-account-information-plan-name-txt',
    planCostTxt: 'profile-tab-account-information-plan-cost-txt',
    planDescriptionTxt: 'profile-tab-account-information-plan-description-txt',
  },
  stbAuthCodePopup: {
    titleLbl: 'profile-stb-auth-code-title-lbl',
    codeLbl: 'profile-stb-auth-code-code-lbl',
  },
  changeInformationTabId: {
    titleLbl: 'profile-tab-change-information-title-lbl',
    firstNameLbl: 'profile-tab-change-information-first-name-lbl',
    firstNameTxt: 'profile-tab-change-information-first-name-txt',
    lastNameLbl: 'profile-tab-change-information-last-name-lbl',
    lastNameTxt: 'profile-tab-change-information-last-name-txt',
    emailLbl: 'profile-tab-change-information-email-lbl',
    emailTxt: 'profile-tab-change-information-email-txt',
    birthdayLbl: 'profile-tab-change-information-birthday-lbl',
    birthdayTxt: 'profile-tab-change-information-birthday-txt',
    phoneNumberLbl: 'profile-tab-change-information-phone-number-lbl',
    phoneNumberTxt: 'profile-tab-change-information-phone-number-txt',
    genderLbl: 'profile-tab-change-information-gender-lbl',
    genderCbx: 'profile-tab-change-information-gender-cbx',
    facebookIdLbl: 'profile-tab-change-information-facebook-id-lbl',
    facebookIdTxt: 'profile-tab-change-information-facebook-id-txt',
    submitBtn: 'profile-tab-change-information-submit-btn',
    errorMessageLbl: 'profile-tab-change-information-error-message-lbl',
  },
  changePasswordTabId: {
    titleLbl: 'profile-tab-change-password-title-lbl',
    oldPasswordLbl: 'profile-tab-change-password-old-password-lbl',
    oldPasswordTxt: 'profile-tab-change-password-old-password-txt',
    newPasswordLbl: 'profile-tab-change-password-new-password-lbl',
    newPasswordTxt: 'profile-tab-change-password-new-password-txt',
    enterNewPasswordLbl: 'profile-tab-change-password-enter-new-password-lbl',
    enterNewPasswordTxt: 'profile-tab-change-password-enter-new-password-txt',
    submitBtn: 'profile-tab-change-password-submit-btn',
    errorMessageLbl: 'profile-tab-change-password-error-message-lbl',
  },
  availablePlanTabId: {
    titleLbl: 'profile-tab-available-plan-title-lbl',
    updatePlanBtn: 'profile-tab-available-plan-submit-btn',
    availablePlanTxt: 'profile-tab-available-plan-available-txt',
    planNameTxt: 'profile-tab-available-plan-plan-name-txt',
    planCostTxt: 'profile-tab-available-plan-plan-cost-txt',
    planDescriptionTxt: 'profile-tab-available-plan-plan-description-txt',
  },
  loginCodeTabId: {
    title: 'profile-tab-login-code-title-txt',
    loginCodeTxt: 'profile-tab-login-code-login-code-txt',
    timeRefresh: 'profile-tab-login-code-time-refresh-txt',
    guideTxt: 'profile-tab-login-code-guide-txt',
  },
  paymentPopup: {
    titleLbl: 'profile-payment-popup-title-lbl',
    planTitleLbl: 'profile-payment-popup-plan-title-lbl',
    planNameTxt: 'profile-payment-popup-plan-name-txt',
    planCostTxt: 'profile-payment-popup-plan-cost-txt',
    planDescriptionTxt: 'profile-payment-popup-plan-description-txt',
    cardLbl: 'profile-payment-popup-card-lbl',
    paymentBtn: 'profile-payment-payment-button-btn',
  },
};
