import { createSlice } from '@reduxjs/toolkit';

const filterSlice = createSlice({
  name: 'filter',
  initialState: {
    filterData: [],
    isOpen: false,
    activeIDs: {},
  },
  reducers: {
    resetFilter(state) {
      state = {
        filterData: [],
        isOpen: false,
        activeIDs: {},
      };
    },
    setFilterData(state, action) {
      const filterData = action.payload;
      state.filterData = filterData;
    },
    setActiveIDs(state, action) {
      state.activeIDs = {
        ...state.activeIDs,
        ...action.payload,
      };
    },
    resetActiveIDs(state, action) {
      state.activeIDs = {};
    },
  },
});

export const { setFilterData, setActiveIDs, resetActiveIDs } = filterSlice.actions;

export default filterSlice.reducer;
