import FETCH from '../utils/fetch';
import * as APIString from '../constants/apiString';
import queryString from 'query-string';

export function getDocumentByType(params) {
  const select = JSON.stringify({
    Document: ['id', 'content'],
  });
  const queryParams = queryString.stringify({ select });
  const { type } = params;

  const path = `/tenants/${APIString.TENANT_SLUG}/tenant_platforms/${APIString.ROOT_PLATFORM_MENU_ID}/documents/by_type/${type}?${queryParams}`;

  return FETCH(path, 'GET', {
    'Content-Type': 'application/json',
  });
}
