import React, { useState, useEffect } from 'react';
import Loader from 'react-loader-spinner';

function OverlayLoading(props) {
  const { type } = props;
  const [typeLoader, setTypeLoader] = useState('Oval');

  useEffect(() => {
    if (type) {
      setTypeLoader(type);
    }
  }, [type]);

  return (
    <div style={styles.overlayLoading}>
      <Loader type={typeLoader} color="#FFFFFF" height="30" width="30" />
    </div>
  );
}

const styles = {
  overlayLoading: {
    width: '100%',
    height: '100%',
    position: 'fixed',
    backgroundColor: '#000000',
    zIndex: 999,
    opacity: 0.7,
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    top: '0',
    left: '0',
  },
};

export default OverlayLoading;
