import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './locales/en';
import vi from './locales/vi';
import ko from './locales/ko';
import { getConfigByKey } from '~features/tenantConfig/services';
import { getLocales } from '~utils/locales';

export const AVAILABEL_LOCALES = getLocales();
export const DEFAULT_LOCALE =
  getConfigByKey('default_locale') || (getConfigByKey('tenant_slug') === 'projectw' ? 'ko' : 'en');

const ls = {
  en: 'en',
  vi: 'vi',
  ko: 'ko',
};
const path = window.location.pathname;
const pathSplit = path.split('/');
let language = JSON.parse(window.localStorage.getItem('persist:root'));
try {
  if (language) {
    language = JSON.parse(language.root);
    language = language.language;
  }
  if (pathSplit.length > 1 && pathSplit[1] in ls) {
    language = pathSplit[1];
  }
} catch (error) {
  language = '';
}

const defaultLng = DEFAULT_LOCALE;

i18n.use(initReactI18next).init({
  lng: language || defaultLng,
  resources: {
    en: en,
    vi: vi,
    ko: ko,
  },
  fallbackLng: defaultLng,
  debug: false,

  ns: ['translations'],
  defaultNS: 'translations',
  compatibilityJSON: 'v2',
  // keySeparator: false,
  interpolation: {
    escapeValue: false,
    formatSeparator: '.',
  },

  react: {
    wait: true,
    bindI18n: 'languageChanged',
    bindI18nStore: '',
    transEmptyNodeValue: '',
    transSupportBasicHtmlNodes: true,
    transKeepBasicHtmlNodesFor: ['br', 'strong', 'i'],
    useSuspense: false,
  },
});

export default i18n;
