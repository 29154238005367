export const INITIAL_HOME_SCREEN = 'INITIAL_HOME_SCREEN';
export const GET_MENU = 'GET_MENU';
export const GET_PAGE = 'GET_PAGE';
export const GET_DETAIL = 'GET_DETAIL';
export const REFRESH_PAGE = 'REFRESH_PAGE';
export const UPDATE_DETAIL_PAGE = 'UPDATE_DETAIL_PAGE';
export const UPDATE_DISCLAIMER = 'UPDATE_DISCLAIMER';
export const UPDATE_EPGLIST = 'UPDATE_EPGLIST';
export const SET_VOLUME = 'SET_VOLUME';
export const SET_CURRENT_LINK = 'SET_CURRENT_LINK';
export const UPDATE_CURATED_LIVE = 'UPDATE_CURATED_LIVE';
export const UPDATE_TOKEN_DRM = 'UPDATE_TOKEN_DRM';
