import styled from 'styled-components';

export const DivStyled = styled.div`
  &.footer {
  }
  .footer {
    &--row {
      margin-top: 0;
    }
    &--term {
      flex-direction: row;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      a,
      p {
        cursor: pointer;
        white-space: nowrap;
        &:hover {
          text-decoration: underline;
          color: ${({ theme }) => theme.primaryColor};
        }
      }
      a:last-child {
        text-transform: none;
      }
    }
    &--info {
      text-transform: uppercase;
      cursor: pointer;
      color: #2574d4;
      &:hover {
        text-decoration: underline;
        color: #2574d4;
      }
    }
    &--layout {
      bottom: 0px;
      width: 100%;
      margin: 0px;
      z-index: 9;
      color: rgb(37, 116, 212);
      background: black;
      padding: 1rem 0;
      /* position: absolute; */
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      align-items: center;
      flex-wrap: wrap;
      overflow-x: hidden;
      font-size: 0.85rem;
    }
    &--address {
      display: flex;
      padding: 0px;
      color: #999999;
      flex-direction: column-reverse;
      align-items: center;
    }
    &--version {
      display: flex;
      justify-content: space-between;
      flex-direction: column-reverse;
      align-items: center;
      color: #8a8a8a;
      p {
        padding-right: 0;
      }
    }
    &--download {
      display: flex;
      justify-content: center;
    }
    &--state {
      display: flex;
      justify-content: flex-end;
    }
    &--contact {
      color: ${({ theme }) => theme.primaryColor};
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: flex-end;
      flex-direction: column;
      line-height: 1rem;
      border-top: 1px solid #555;
      padding-top: 1rem;
      a {
        color: #8a8a8a;
        font-size: 1rem;
        margin: 0px 0px 10px;
      }
      p {
        padding-right: 0;
        i,
        span {
          color: #8a8a8a;
        }
      }
    }
    &--mailto {
      color: ${({ theme }) => theme.primaryColor}!important;
    }
  }
  @media (min-width: 576px) {
    .footer {
      &--layout {
        font-size: 1rem;
      }
    }
  }
  @media (min-width: 768px) {
    .footer {
      &--term {
        flex-direction: row;
        justify-content: center;
      }
    }
  }
  @media (min-width: 992px) {
    .footer {
      &--row {
        margin-top: 0rem;
      }
      &--term {
        justify-content: flex-start;
      }
      &--layout {
        flex-direction: row;
        text-align: left;
        display: block;
      }
      &--contact {
        flex-direction: row;
        padding-bottom: 0;
        border-top: none;
        padding-top: 0;
        > p,
        > a {
          padding-left: 1.5rem;
          i,
          span {
            color: #8a8a8a;
          }
        }
      }
      &--address {
        flex-direction: column;
        align-items: initial;
      }
      &--version {
        flex-direction: column;
        align-items: flex-end;
        /* p {
          padding-right: 1.5rem;
        } */
      }
      &--download {
        flex-direction: row;
        justify-content: flex-end;
        margin-bottom: 1rem;
        a {
          cursor: pointer;
          padding-left: 1.5rem;
        }
      }
    }
  }
  @media (min-width: 1200px) {
  }
`;
