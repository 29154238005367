import * as historyActions from './historySlice';
import * as boxService from '~features/box/services';

export const setPreviousLocation = (payload = true) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch(historyActions.setPreviousLocation(payload));
      dispatch(boxService.setPreviousLocation(payload));
      resolve(payload);
    });
  };
};
