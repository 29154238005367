export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const LOGIN_PROGRESS = 'LOGIN_PROGRESS';

export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAILURE = 'REGISTER_FAILURE';
export const REGISTER_PROGRESS = 'REGISTER_PROGRESS';

export const RESETPASS_REQUEST = 'RESETPASS_REQUEST';
export const RESETPASS_SUCCESS = 'RESETPASS_SUCCESS';
export const RESETPASS_FAILURE = 'RESETPASS_FAILURE';

export const REQUESTCODE_REQUEST = 'REQUESTCODE_REQUEST';
export const REQUESTCODE_SUCCESS = 'REQUESTCODE_SUCCESS';
export const REQUESTCODE_FAILURE = 'REQUESTCODE_FAILURE';

export const SIGNOUT_SUCCESS = 'SIGNOUT_SUCCESS';

export const UPDATE_PROFILE_SUCCESS = 'UPDATE_PROFILE_SUCCESS';
export const UPDATE_EMAIL = 'UPDATE_EMAIL';
export const GET_PROFILE = 'GET_PROFILE';
export const UPDATE_ACCOUNT_SUPSCRIPTION = 'UPDATE_ACCOUNT_SUPSCRIPTION';

export const REGISTER_LOGIN_SUCCESS = 'REGISTER_LOGIN_SUCCESS';

export const UPDATE_FIRSTNAME = 'UPDATE_FIRSTNAME';
export const UPDATE_ACCOUNT_PAYMENT_METHOD = 'UPDATE_ACCOUNT_PAYMENT_METHOD';
export const UPDATE_ACCOUNT_PAYMENT_METHOD_PAYPAL = 'UPDATE_ACCOUNT_PAYMENT_METHOD_PAYPAL';

export const UPDATE_IP = 'UPDATE_IP';
export const SET_PAID_CONTENT = 'SET_PAID_CONTENT';

export const SET_IS_SHOW_LOGIN = 'SET_IS_SHOW_LOGIN';
export const RESET_PAYMENT_METHOD = 'RESET_PAYMENT_METHOD';
