import { tenantEnvConfig } from '../../constants/tenantConfig/tenantEnvConfig';
import { tenantStrings } from '../../constants/tenantConfig/tenantStrings';
import { tenantFeatureFlags } from '../../constants/tenantConfig/tenantFeatureFlags';
import { integrationConfigs } from '../../constants/tenantConfig/integrationConfigs';
import { getDeepProp } from '../../utils/utils';

let currentLanguage = '';
const rootState = JSON.parse(window.localStorage.getItem('persist:root'));

if (rootState) {
  try {
    currentLanguage = JSON.parse(rootState.root).language;
  } catch (error) {}
}

export { getConfigByKey };

export const tenantConfig = {
  _ssr: false,
  ...tenantEnvConfig,
  ...tenantStrings,
  features: {
    ...tenantFeatureFlags,
  },
  integrations: {
    ...integrationConfigs,
  },
};

const getConfigByKey = (name = '') => {
  const configs = tenantConfig;
  const value = getDeepProp(configs, name, currentLanguage) || '';
  return value;
};
