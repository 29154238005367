export const INITIAL_HOME_SCREEN = 'INITIAL_HOME_SCREEN';
export const GET_MENU = 'GET_MENU';
export const GET_PAGE = 'GET_PAGE';
export const GET_PAGE_REQUEST = 'GET_PAGE_REQUEST';
export const GET_DETAIL = 'GET_DETAIL';
export const REFRESH_PAGE = 'REFRESH_PAGE';
export const GET_FINAL = 'GET_FINAL';
export const GET_RECENT = 'GET_RECENT';
export const GET_RIBBON_TARGET = 'GET_RIBBON_TARGET';
export const UPDATE_STATUS_REMOVE_PROCESS = 'UPDATE_STATUS_REMOVE_PROCESS';
export const UPDATE_SEARCH = 'UPDATE_SEARCH';
export const UPDATE_PAGE_SLUG = 'UPDATE_PAGE_SLUG';
export const SET_EXTRA_BANNERS = 'SET_EXTRA_BANNERS';
