import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Modal } from 'react-bootstrap';
import profilePageId from '../../../constants/ids/profilePageId';
import { getConfigByKey } from '../../tenantConfig/services';
import { setPrivacyAndPolicy } from '~components/global/globalServices';

const privacyPolicyLink = getConfigByKey('privacyPolicyLink');

class PrivacyPolicy extends Component {
  static propTypes = {
    modalShow: PropTypes.bool,
    showModal: PropTypes.func,
  };

  componentDidMount() {
    const { dpSetPrivacyAndPolicy } = this.props;
    if (privacyPolicyLink) {
      return;
    }
    dpSetPrivacyAndPolicy();
  }

  _modalShow() {
    const { showModal } = this.props;
    showModal();
  }

  render() {
    if (privacyPolicyLink) {
      return null;
    }
    const { modalShow, documents } = this.props;

    return (
      <Modal
        show={modalShow}
        onHide={() => this._modalShow()}
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton style={{ border: 'none' }} />
        <Modal.Body style={{ height: 700, overflow: 'auto' }}>
          <div
            style={{ padding: 50, paddingTop: 0 }}
            dangerouslySetInnerHTML={{ __html: documents.privacyAndPolicy.content || '' }}
          ></div>
        </Modal.Body>
      </Modal>
    );
  }
}

PrivacyPolicy.defaultProps = {
  showModal: () => {},
  modalShow: false,
};

const mapDispatchToProps = {
  dpSetPrivacyAndPolicy: () => setPrivacyAndPolicy(),
};

const mapStateToProps = state => ({
  documents: state.global.documents,
});

export default connect(mapStateToProps, mapDispatchToProps)(PrivacyPolicy);
