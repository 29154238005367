import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { withTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
// import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import styled from 'styled-components';
import { getConfigByKey } from '~features/tenantConfig/services';
import { setOpen } from './services';

const DialogStyled = styled(Dialog)`
  font-family: Open Sans;
  text-align: center;
  font-size: 10px;
  .MuiDialogContent-dividers {
    border: none;
  }
  .MuiDialogTitle-root {
    padding: 10px 20px;
  }
  .MuiDialog-paper {
    margin: 10px;
  }
  .MuiDialog-paperFullWidth {
    width: 100%;
  }
  .btn-close {
    color: #121212;
    padding: 0;
    line-height: 0;
    width: 30px;
    height: 30px;
    font-size: 18px;
    position: absolute;
    top: 4px;
    right: 4px;
    top: 0px;
    right: 0px;
    font-size: 14px;
  }
  .wrap {
    position: relative;
    width: 100%;
    padding-top: calc(385 / 615 * 100%);
    &-inside {
      top: 0;
      left: 0;
      position: absolute;
      width: 100%;
      height: 100%;
      /* background-color: red; */
      background-image: url(${require('~img/img/sctv/ppv-app-background.png')});
      background-position: center;
      background-size: contain;
    }
  }
  .title {
    font-size: 29px;
    font-size: 14px;
    color: #283237;
    text-decoration: none solid rgb(40, 50, 55);
    text-align: center;
    font-weight: bold;
  }
  .logo {
    width: calc(380 / 615 * 100%);
    margin: 26px 0 30px 0;
    margin: 14px 0 18px 0;
  }
  .smaller-title {
    font-size: 25px;
    font-size: 12px;
    color: #283237;
    font-weight: bold;
  }
  .actions {
    display: flex;
    justify-content: center;
    margin-top: 5px;
    margin-top: -5px;
    img {
      width: 60px;
    }
  }
  @media (min-width: 375px) {
    .MuiDialog-paper {
      margin: 32px;
    }
  }
  // Small devices (landscape phones, 576px and up)
  @media (min-width: 576px) {
    .MuiDialogTitle-root {
      padding: 16px 24px;
    }
    .btn-close {
      font-size: 18px;
      top: 15px;
      right: 15px;
    }
    .title {
      margin-top: 1.5rem;
      font-size: 29px;
    }
    .smaller-title {
      font-size: 25px;
    }
    .logo {
      margin: 24px 0 26px 0;
    }
    .actions {
      margin-top: 10px;
      img {
        width: 136px;
      }
    }
  }

  // Medium devices (tablets, 768px and up)
  @media (min-width: 768px) {
  }

  // Large devices (desktops, 992px and up)
  @media (min-width: 992px) {
  }

  // X-Large devices (large desktops, 1200px and up)
  @media (min-width: 1200px) {
  }

  // XX-Large devices (larger desktops, 1400px and up)
  @media (min-width: 1400px) {
  }
`;

const DialogTitle = props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton className="btn-close" aria-label="close" onClick={onClose}>
          &#10006;
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
};

const DialogAppSubscription = props => {
  const { open = false, setOpen } = props;

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <DialogStyled
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth
        maxWidth="sm"
      >
        <div className="wrap">
          <div className="wrap-inside">
            <DialogTitle id="customized-dialog-title" onClose={handleClose}></DialogTitle>
            <DialogContent>
              <Typography className="title">Đăng ký gói dịch vụ trên ứng dụng</Typography>
              <img className="logo" src={getConfigByKey('nav_bar_logo')} />
              <p className="smaller-title">để trải nghiệm các nội dung VIP</p>
            </DialogContent>
            <DialogActions className="actions">
              <a target="_blank" href="https://apps.apple.com/vn/app/sctv/id1564652065">
                <img src={require(`~img/img/appStoreIcon.svg`)} />
              </a>
              <a
                target="_blank"
                href="https://play.google.com/store/apps/details?id=io.vimai.sctvonline"
              >
                <img src={require(`~img/img/googlePlayIcon.svg`)} />
              </a>
            </DialogActions>
          </div>
        </div>
      </DialogStyled>
    </div>
  );
};

const mapStateToProps = state => ({
  open: state.svodApp.open,
});

const mapDispatchToProps = {
  setOpen: payload => setOpen(payload),
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(DialogAppSubscription)),
);
