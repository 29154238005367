import moment from 'moment';
import { VERSION, DEBUG } from '../constants/envConfig';
import TENANT_CONFIG from '../constants/tenantConfig';
import jquery from 'jquery';
import { LIVE_EVENT } from '~core/constants/contentConstants';
import { getCategoryName } from '~core/method/contentMethod';
import { UAParser } from 'ua-parser-js';

const userAgent = UAParser(navigator.userAgent);

export {
  verifyPhoneNumber,
  getLinkPlay,
  toStringTime,
  verifyEmailFormat,
  secondsToTime,
  getDeviceType,
  isMobile,
  objectToSlug,
  contentTypes,
  FormatDayTime,
  getBase64,
  slugify,
  debug,
  getCategories,
  loadMore,
  getTenantConfig,
  unique,
  getDeviceResolution,
};

function toStringTime(timestamp) {
  const ndate = new Date(timestamp * 1000);
  let hour = ndate.getHours();
  let min = ndate.getMinutes();

  if (hour.toString().length === 1) {
    hour = '0' + hour;
  }
  if (min.toString().length === 1) {
    min = '0' + min;
  }
  return hour + ':' + min;
}

function getLinkPlay(entityDetail) {
  let linkPlay = '';

  if (entityDetail.type === 2) {
    if (entityDetail && entityDetail.default_episode) {
      if (entityDetail.default_episode.play_info) {
        if (entityDetail.default_episode.play_info.data) {
          linkPlay = entityDetail.default_episode.play_info.data.hls_link_play;
        }
      }

      if (!linkPlay) {
        if (entityDetail.default_episode.type === 4) {
          linkPlay = entityDetail.default_episode.link_play;
        }
      }
    }
  } else if (entityDetail.type === 1) {
    if (entityDetail && entityDetail.play_info && entityDetail.play_info.error !== 1) {
      linkPlay = entityDetail.play_info.data.hls_link_play;
    }

    if (!linkPlay) {
      linkPlay = entityDetail.link_play;
    }
  } else if (entityDetail.type === 4) {
    if (entityDetail && entityDetail.play_info) {
      if (entityDetail.play_info.error !== 1) {
        linkPlay = entityDetail.play_info.data.hls_link_play;
      } else {
        linkPlay = entityDetail.link_play;
      }
    }
  } else if (entityDetail.type === 5) {
    if (entityDetail.play_info && entityDetail.play_info.error !== 1) {
      linkPlay = entityDetail.play_info.data.link_play;
    }
  }
  if (!linkPlay) {
    linkPlay = entityDetail.link_play;
  }
  return linkPlay;
}

function verifyEmailFormat(email) {
  email = email.trim();
  const emailReg =
    /(?!.*\.{2})^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([\t]*\r\n)?[\t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([\t]*\r\n)?[\t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i;
  if (!emailReg.test(email)) return false;
  return true;
}

const verifyPhoneNumber = phoneNumber => {
  const regex = /^\+?[0-9]{10,12}$/;
  return regex.test(phoneNumber);
};

function secondsToTime(secs) {
  const hours = Math.floor(secs / (60 * 60));

  const divisor_for_minutes = secs % (60 * 60);
  const minutes = Math.floor(divisor_for_minutes / 60);

  const divisor_for_seconds = divisor_for_minutes % 60;
  const seconds = Math.ceil(divisor_for_seconds);

  const obj = {
    h: hours,
    m: minutes,
    s: seconds,
  };

  return obj;
}

const getDeviceType = () => {
  const ua = navigator.userAgent;
  if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
    return 'tablet';
  }
  if (
    /Mobile|iP(hone|od|ad)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
      ua,
    )
  ) {
    return 'mobile';
  }
  return 'desktop';
};

const isMobile = () => {
  if (['tablet', 'mobile'].indexOf(getDeviceType()) >= 0) {
    return true;
  }
  return false;
};

const contentTypes = item => {
  let statusText = 'Movie';
  if (item) {
    switch (item.type) {
      case 2:
        statusText = 'Show';
        break;
      case 3:
        statusText = 'Season';
        break;
      case 4:
        statusText = 'Episode';
        break;
      case 5:
        statusText = 'Channel';
        break;
      case 6:
        statusText = 'Clip';
        break;
      case 7:
        statusText = 'Curated live';
      case LIVE_EVENT:
        statusText = 'Live';
        break;
    }
  }
  return statusText;
};

const getCategories = item => {
  return getCategoryName.call(item);
};

const FormatDayTime = date => {
  const time = moment(date).format('DD/MM/YYYY');
  return time;
};

const ChangeToSlug = (str, replace = '-') => {
  if (Array.isArray(str)) {
    return str;
  }
  if (!str) {
    return '';
  }
  let slug = '';
  slug = str.toString().toLowerCase();
  slug = slug.replace(/á|à|ả|ạ|ã|ă|ắ|ằ|ẳ|ẵ|ặ|â|ấ|ầ|ẩ|ẫ|ậ/gi, 'a');
  slug = slug.replace(/é|è|ẻ|ẽ|ẹ|ê|ế|ề|ể|ễ|ệ/gi, 'e');
  slug = slug.replace(/i|í|ì|ỉ|ĩ|ị/gi, 'i');
  slug = slug.replace(/ó|ò|ỏ|õ|ọ|ô|ố|ồ|ổ|ỗ|ộ|ơ|ớ|ờ|ở|ỡ|ợ/gi, 'o');
  slug = slug.replace(/ú|ù|ủ|ũ|ụ|ư|ứ|ừ|ử|ữ|ự/gi, 'u');
  slug = slug.replace(/ý|ỳ|ỷ|ỹ|ỵ/gi, 'y');
  slug = slug.replace(/đ/gi, 'd');
  // slug = slug.replace(/\`|\~|\!|\@|\#|\||\$|\%|\^|\&|\*|\(|\)|\+|\=|\,|\.|\/|\?|\>|\<|\'|\"|\:|\|_/gi, '')
  slug = slug.replace(/\`|\~|\!|\@|\#|\||\$|\%|\^|\&|\*|\(|\)|\+|\=|\/|\>|\<|\'|\"|\:|\|_/gi, '');
  slug = slug.replace(/ /gi, '-');
  slug = slug.replace(/\-\-\-\-\-/gi, replace);
  slug = slug.replace(/\-\-\-\-/gi, replace);
  slug = slug.replace(/\-\-\-/gi, replace);
  slug = slug.replace(/\-\-/gi, replace);
  slug = '@' + slug + '@';
  slug = slug.replace(/\@\-|\-\@|\@/gi, '');
  return slug;
};
const slugify = (text, replace = '-') => {
  return ChangeToSlug(text, replace);
};
const objectToSlug = (object = {}) => {
  if (!Object.keys(object)) {
    return {};
  }
  let result = {};
  if (object.search_term) {
    return object;
  }
  Object.keys(object).forEach(value => {
    result[value] = slugify(object[value]);
  });
  return result;
};

const getBase64 = (file, callback) => {
  let reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = function () {
    callback(reader.result);
  };
  reader.onerror = function (error) {
    console.log('Error: ', error);
  };
};

const debug = fn => {
  const isDebug = DEBUG === 'true';
  if (!isDebug || typeof fn !== 'function') {
    return;
  }
  fn();
};

const loadMore = ($el, heightDevice, isLoadMore) => {
  return new Promise((resolve, reject) => {
    let $footer = jquery('.footer--row');
    if (!$el || !$footer) {
      resolve(null);
      return;
    }
    let heightRibbon = $el.clientHeight + $el.offsetTop;
    let heightFooter = $footer[0].clientHeight;
    let windowHeight = window.innerHeight;
    let scrollTop = jquery(window).scrollTop();
    let heigthWhenScroll = document.body.scrollHeight - heightFooter - windowHeight * 0.2;

    if (heightDevice - heightFooter === heightRibbon) {
      resolve(null);
      return;
    }
    if (scrollTop + windowHeight >= heigthWhenScroll && !isLoadMore) {
      resolve(true);
    }
    resolve(null);
  });
};

function getTenantConfig(tenantSlug) {
  let tenantConfig = TENANT_CONFIG[tenantSlug];
  if (!tenantConfig) {
    tenantConfig = TENANT_CONFIG['odv'];
  }

  return tenantConfig;
}

function unique(arr) {
  const unique = arr
    .map(e => e['slug'])
    .map((e, i, final) => final.indexOf(e) === i && i)
    .filter(e => arr[e])
    .map(e => arr[e]);
  return unique;
}

function getDeviceResolution() {
  let width = window.innerWidth;
  let height = window.innerHeight;
  let resolutionScale = Math.max(width, height);
  let deviceResolution = 'web@sd';
  switch (true) {
    case resolutionScale <= 920:
      deviceResolution = 'web@sd';
      break;
    case resolutionScale <= 1480:
      deviceResolution = 'web@hd';
      break;
    case resolutionScale <= 2120:
      deviceResolution = 'web@fhd';

      break;
    case resolutionScale <= 2760:
      deviceResolution = 'web@qhd';
      break;
    default:
      deviceResolution = 'web@sd';
      break;
  }
  return {
    image_resolution_scale: deviceResolution,
  };
}

export const getDeepProp = (obj, propName, language) => {
  let hasError = false;
  let result = null;
  try {
    result = eval(`obj.${propName}_${language}`);
  } catch (error) {
    hasError = true;
  }
  if (result !== null && result !== undefined) {
    return result;
  }
  try {
    return eval(`obj.${propName}`);
  } catch (error) {
    return undefined;
  }
};

export const getCustomUserAgent = () => {
  const { os, device, browser } = userAgent;
  const appInfo = ['vn.sctvonline.web', VERSION];
  const deviceInfo = [`${os.name}/${os.version}`, device.model, device.type ?? getDeviceType()];
  return `${appInfo.join('/')} (${deviceInfo.join('; ')})`;
};

export const CUSTOM_X_USER_AGENT = getCustomUserAgent();
