import { createSlice } from '@reduxjs/toolkit';

const svodAppSlice = createSlice({
  name: 'svodApp',
  initialState: {
    open: false,
  },
  reducers: {
    setOpen(state, action) {
      state.open = action.payload;
    },
  },
});

export const { setOpen } = svodAppSlice.actions;

export default svodAppSlice.reducer;
