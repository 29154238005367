import { SUBCRPITON_BADGE } from '~core/constants';
import _ from 'lodash';
import { getConfigByKey } from '~features/tenantConfig/services';
export {
  checkAndRenderBadge,
  subscriptionContent,
  subscriptionAccount,
  renderBadgeContent,
  packageTheLowest,
  packageAdjacent,
  checkSubscriptionContentTheHighest,
  getInfoSubscriptionUser,
};

function checkSubscriptionContentTheHighest() {
  try {
    const { subscription_plan_info, entityDetail } = this;
    if (subscriptionAccount.call(subscription_plan_info) > subscriptionContent.call(entityDetail)) {
      return true;
    }
    return false;
  } catch (err) {
    return false;
  }
}

function subscriptionAccount() {
  try {
    const { sub_tier } = this;
    const { tier } = sub_tier;
    return tier;
  } catch (err) {}
}

function subscriptionContent() {
  try {
    const { min_sub_tier } = this;
    if (_.isEmpty(min_sub_tier)) {
      return packageTheLowest().tier;
    }
    const { tier } = min_sub_tier;
    return tier;
  } catch (err) {}
}

function packageTheLowest() {
  //TODO : Get package with prioritize lowest if you content that have not set minium_tier yet
  try {
    const defaulValuePackage = getConfigByKey('subscription_tiers');
    const packageNull = _.maxBy(defaulValuePackage, 'tier');
    const { is_private, name } = !_.isEmpty(packageNull) && packageNull;
    if (is_private) {
      return packageNull;
    }
    return {};
  } catch (err) {
    return {};
  }
}

function packageAdjacent() {
  //TODO : Get Package Adjacent with package have prioritize lowest - When Can Watch Upgrade
  try {
    const defaulValuePackage = getConfigByKey('subscription_tiers');
    const sortPackage = _.sortBy(defaulValuePackage);
    const activePackage = sortPackage.filter((item, index) => {
      return index === 1 && !item.is_private;
    });
    return activePackage;
  } catch (err) {
    return '';
  }
}

function renderBadgeContent() {
  try {
    const { min_sub_tier } = this;
    if (!_.isEmpty(min_sub_tier)) {
      const { name, tier, is_private } = min_sub_tier;
      if (is_private) {
        return packageAdjacent().map(item => item.name);
      }
      return name;
    }
    return packageAdjacent().map(item => item.name);
  } catch (err) {
    return '';
  }
}

function checkAndRenderBadge() {
  try {
    const { min_sub_tier } = this;
    if (!_.isEmpty(min_sub_tier)) {
      const { name, tier } = min_sub_tier;
      return name;
    }
  } catch (err) {
    return '';
  }
}

function getInfoSubscriptionUser() {
  try {
    const { sub_tier } = this;
    if (!_.isEmpty(sub_tier)) {
      return sub_tier.name;
    }
    return '';
  } catch (err) {
    return '';
  }
}
