import FETCH from '../utils/fetch';
import * as APIString from '../constants/apiString';
import { store } from '../store/index';
import queryString from 'query-string';

const loginSSO = (data = {}) => {
  const path = `/tenants/${APIString.TENANT_SLUG}/accounts/logins/sso`;
  return FETCH(
    path,
    'POST',
    {
      'Content-Type': 'application/json',
      Authorization: data.sso_token,
    },
    JSON.stringify(data),
  );
};

const getPaidContent = () => {
  const { account } = store.getState().auth;
  let Authorization = '';
  if (!account) return;
  const accountId = account.profile.id;
  Authorization = account.access_token ? account.access_token : '';
  const path = `/tenants/${APIString.TENANT_SLUG}/accounts/${accountId}/paid_contents/`;
  return FETCH(path, 'GET', {
    Authorization,
  });
};

export const logout = () => {
  const { account } = store.getState().auth;
  let Authorization = '';
  if (!account) return;

  Authorization = account.access_token ? account.access_token : '';
  const path = `/tenants/${APIString.TENANT_SLUG}/accounts/logout`;
  return FETCH(path, 'POST', {
    Authorization,
  });
};

export { loginSSO, getPaidContent };
