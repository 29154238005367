import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Col, Row } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import FooterV2 from '../../footer/footerV2';
import styled from 'styled-components';
import { checkGeoBlocking } from '../../../app/services';
import { getConfigByKey, tenantConfig } from '../../tenantConfig/services';
import { setUserProperties } from './../../../services/analytics/SetUserProperties';
import { Link } from 'react-router-dom';
import { hideMenu, showMenu } from '~components/global/globalServices';
class BlockCountry extends Component {
  static propTypes = {};

  constructor(props) {
    super(props);
    this.myInterval = null;
    this.state = {
      logo: getConfigByKey('nav_bar_logo'),
    };
  }
  componentDidMount() {
    const { blockRegionSubscription } = this.props;
    if (blockRegionSubscription) {
      return;
    }
    this._checkBlock();
  }

  componentWillMount() {
    const { dpHideMenu } = this.props;
    dpHideMenu();
  }

  componentWillUnmount() {
    const { dpShowMenu } = this.props;
    dpShowMenu();
  }

  componentWillReceiveProps(nextProps) {
    const { logo } = this.state;
    if (!logo && getConfigByKey('nav_bar_logo')) {
      this.setState({
        logo: getConfigByKey('nav_bar_logo'),
      });
    }
  }

  _checkBlock() {
    const { dispatchCheckGeoBlocking } = this.props;
    return;
  }

  render() {
    const { t, blockRegionSubscription } = this.props;
    const { logo } = this.state;
    let text = {
      txtDescription: blockRegionSubscription
        ? t('box.txtBlockContrySubscription')
        : t('box.sorry', {
            site_name: getConfigByKey('site_name'),
            tenantName: getConfigByKey('name'),
          }),
    };
    return (
      <div style={Classes.background}>
        <RowStyled style={{ height: '100%', display: 'flex', paddingBottom: '5rem' }}>
          <Link className="v-block--logo" to={'/'} onClick={() => setUserProperties()}>
            <img src={logo} />
          </Link>
          <Col className="v-block--col" sm={12} style={{ ...Classes.block_container }}>
            <p className="v-block--title" style={{ fontWeight: 'bold' }}>
              {getConfigByKey('site_name')}
            </p>
            <p className="v-block--description">
              {/* {t('box.unlimited movies')} {t('box.ondeman', { sologan: getConfigByKey('sologan') })}{' '}
              {t('box.tvShow')} */}
              Xem Phim Theo Ý Bạn
            </p>
            <p className="v-block--sr" style={{ textTransform: 'none', fontStyle: 'Italic' }}>
              {text.txtDescription}
            </p>
            <div className="v-block--footer" style={{ textTransform: 'none' }}>
              {/* <p>{t('subscriptions.footer.ContactUs')}</p> */}
              <p>Chi tiết liên hệ</p>
              <div style={{ cursor: 'pointer', color: '#2574d4' }}>
                <i className="fa fa-phone" aria-hidden="true" style={{ color: '#8a8a8a' }}></i>
                <span style={{ color: '#8a8a8a' }}> : </span>
                {getConfigByKey('phone_number_1')}
              </div>
              {/* <div style={{ color: '#2574d4' }}>
                <i className="fa fa-comment" aria-hidden="true" style={{ color: '#8a8a8a' }}>
                  {' '}
                  :{' '}
                </i>{' '}
                {getConfigByKey('phone_number_2')}
              </div> */}
              <div style={{ cursor: 'pointer', color: '#2574d4' }}>
                <i className="fa fa-envelope-o" aria-hidden="true" style={{ color: '#8a8a8a' }}>
                  {' '}
                  :{' '}
                </i>{' '}
                {t('subscriptions.footer.email', { email: getConfigByKey('email') })}
              </div>
            </div>
            <p className="v-block--address">
              <i className="fa fa-map-marker" aria-hidden="true"></i>
              {` `}31-33 Đinh Công Tráng, P. Tân Định, Q.1, TP.HCM
            </p>
          </Col>
          {/* {getConfigByKey('features.bottom_bar_information', true) && (
            <div className="v-block--adress">
              <p>{getConfigByKey('address.copyright')}</p>
              <p>{getConfigByKey('address.copyrightV2')}</p>
              <p>{getConfigByKey('companyReg')}</p>
              <p>{getConfigByKey('address.address')}</p>
              <p>{getConfigByKey('address.state')}</p>
            </div>
          )} */}
          <Link
            className="v-block--home"
            style={{ display: !blockRegionSubscription && 'none' }}
            to={'/'}
            onClick={() => setUserProperties()}
          >
            <i className="fa fa-angle-double-left" /> {t('box.txtHome')}
          </Link>
        </RowStyled>
      </div>
    );
  }
}

const Classes = {
  block_container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#fff',
    flexDirection: 'column',
  },
  background: {
    backgroundImage: `url(${require('../../../assets/img/background1.jpeg')})`,
    width: '100%',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    position: 'absolute',
    textTransform: 'uppercase',
    fontFamily: 'Open sans',
    height: '100%',
  },
};

const RowStyled = styled.div`
  &.v-block {
  }
  .v-block {
    &--address {
      font-family: Open Sans;
      font-size: 14px;
      color: #999999;
      text-decoration: none solid rgb(153, 153, 153);
      text-transform: initial;
      margin-top: 13px;
    }
    &--logo {
      object-fit: cover;
      position: absolute;
      top: 19px;
      left: 15px;
      z-index: 9;
      img {
        max-width: 7rem;
      }
    }
    &--title {
      font-size: 3.5rem;
      text-align: center;
      text-transform: initial;
    }
    &--description {
      font-size: 1.5rem;
      text-align: center;
      text-transform: capitalize;
      margin-top: -1.5rem;
    }
    &--sr {
      font-size: 1.25rem;
      text-align: center;
      margin-top: 22px;
      margin-top: 20px;
    }

    &--col {
      width: 100%;
    }
    &--footer {
      border-top: 0.5px solid #666666;
      padding-top: 2rem;
      display: flex;
      flex-direction: column;
      text-align: center;
      position: fixed;
      bottom: 2rem;
      width: 80%;
      p {
        padding: 0 0;
      }
      div {
        padding-right: 0;
        margin-bottom: 0.5rem;
      }
      font-size: 1rem;
    }
    &--adress {
      text-align: right;
      position: fixed;
      color: #999999;
      right: 27px;
      display: none;
      bottom: 27px;
      text-transform: capitalize;
      font-size: 1rem;
    }
    &--home {
      position: fixed;
      color: #fff;
      left: 15px;
      bottom: 27px;
      text-transform: capitalize;
      font-size: 1.25rem;
      display: none;
      :hover {
        text-decoration: none;
      }
    }
  }
  @media (min-width: 576px) {
  }

  @media (min-width: 768px) {
    .v-block {
      &--logo {
        left: 45px;
        img {
          max-width: 11rem;
        }
      }
      &--title {
        font-size: 5.5rem;
      }
      &--description {
        font-size: 2rem;
      }
      &--sr {
        font-size: 1.75rem;
      }
      &--footer {
        display: flex;
        text-transform: none;
        flex-direction: row;
        position: relative;
        bottom: 0;
        width: auto;
        p {
          padding: 0 1.5rem;
        }
        div {
          padding-right: 1.5rem;
          margin-bottom: 0rem;
        }
        font-size: 1rem;
      }
      &--adress {
        display: block;
      }
      &--home {
        left: 45px;
        display: block;
      }
    }
  }

  @media (max-width: 812px) and (orientation: landscape) {
    .v-block {
      &--adress {
        display: none;
      }
    }
  }

  @media (min-width: 992px) {
    .v-block {
      &--title {
        font-size: 120px;
      }
      &--description {
        font-size: 55px;
        margin-top: -2rem;
      }
      &--sr {
        font-size: 35px;
        margin-top: 32px;
        margin-bottom: 30px;
      }
      &--address {
        font-size: 18px;
      }
      &--col {
      }
      &--footer {
        font-size: 1.25rem;
      }
    }
  }

  @media (min-width: 1200px) {
  }
`;

BlockCountry.defaultProps = {
  showLoginAction: () => {},
  showForgotPassAction: () => {},
};

const mapStateToProps = state => ({
  account: state.auth.account,
  isBlock: state.root.isBlock,
});

const mapDispatchToProps = {
  dispatchCheckGeoBlocking: () => checkGeoBlocking(),
  dpHideMenu: () => hideMenu(),
  dpShowMenu: () => showMenu(),
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(BlockCountry)),
);
