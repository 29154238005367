import React, { useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { openRequestSignInSelector } from '../globalSlice';
import { setOpenRequestSignIn, showLoginModal } from '../globalServices';

import styled from 'styled-components';

const RequestSignInStyled = styled(Modal)`
  top: 22%;
  .modal-content {
    background-image: url(${require('~img/img/sctv/ppv-app-background.png')});
    background-position: center top;
    /* background-position-y: top; */
    background-size: 155%;
    background-repeat: no-repeat;
    max-width: 562px;
  }
  .modal-header {
    padding: 0;
    border: none;
  }

  .modal-body {
    text-align: center;
  }

  .modal-footer {
    border: none;
    text-align: center;
    margin-bottom: 20px;
  }

  .close {
    font-weight: 300;
    color: #121212;
    opacity: 1;
    font-size: 28px;
    line-height: 20px;
    padding: 3px 10px;
  }
  .image-wrap {
    width: 117px;
    display: inline-block;
    img {
      width: 100%;
      max-width: 100%;
    }
  }
  .description {
    font-family: Open Sans;
    font-size: 14px;
    color: #1e232a;
    text-decoration: none solid rgb(30, 35, 42);
    text-align: center;
    margin-top: 13px;
    margin-bottom: -5px;
  }
  .btn {
    font-family: Noto Sans;
    font-size: 20px;
    color: #ffffff;
    text-decoration: none solid rgb(255, 255, 255);
    text-align: center;
    font-weight: bold;
    border-radius: 10px;
    background-color: #e72d30;
    padding: 10px 35px;
  }
  @media (min-width: 768px) {
    .modal-content {
      background-position: center top;
      background-size: 110% auto;
    }
    .close {
      padding: 17px;
    }
    .description {
      font-size: 18px;
    }
    .btn {
      padding: 13px 53px;
    }
  }
`;

function RequestSignIn() {
  const open = useSelector(openRequestSignInSelector);
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(setOpenRequestSignIn(false));
  };

  const handleOpenLoginForm = () => {
    handleClose();
    dispatch(showLoginModal());
  };

  return (
    <RequestSignInStyled show={open} onHide={handleClose}>
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <div className="image-wrap">
          <img src={require('~img/img/sctv/watching-a-movie.png')} />
        </div>
        <p className="description">Vui lòng đăng nhập để xem nội dung này.</p>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn" onClick={handleOpenLoginForm}>
          Đăng Nhập
        </button>
      </Modal.Footer>
    </RequestSignInStyled>
  );
}

export default RequestSignIn;
