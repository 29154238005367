import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  body {
    background: ${({ theme }) => theme.body.background};
    font-family: ${({ theme }) => theme.body.font};
    .rootContainer {
      background: ${({ theme }) => theme.homePage.background};
      font-family: ${({ theme }) => theme.body.font};
      .v-home--container,
      .ribbonPageContainer,
      .ribbonContainer{
        background: ${({ theme }) => theme.homePage.backgroundRibbon};
      }
      .ribbonHeadAll, .texttruncate {
        color: ${({ theme }) => theme.ribbon.itemNameColor};
        display: ${({ theme }) => theme.ribbon.displayTitleItem};
      }
      .language--name {
        color: ${({ theme }) => theme.primaryColor};
      }
    }
  }
`;

export default GlobalStyles;
