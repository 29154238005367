import * as types from './actionTypes';

const initialState = [];

export default function faq(state = initialState, action = {}) {
  switch (action.type) {
    case types.UPDATE_FAQS:
      return [...action.response];
    default:
      return state;
  }
}
