import { combineReducers } from 'redux';
import { root } from '../app/reducers';
import { auth } from '../features/auth/reducers';
import home from '../features/homepage/reducers';
import alertModal from '../components/alertModal/reducers';
import toast from '../components/toast/reducers';
import landingPage from '../features/landingpage/reducers';
import detailPage from '../features/detailpage/reducers';
import box from '../features/box/reducers';
import loading from '../features/loadingPage/reducers';
import filter from '../components/filter/filterSlice';
import backdrop from '../components/backdrop/backdropSlice';
import faq from '../features/Faq/reducers';
import tenantConfig from '../features/tenantConfig/reducers';
import youtubePage from '../features/youtubePage/reducers';
import blockCountry from '../features/blockCountry/blockingSlice';
import historyReducer from '../features/history/historySlice';
import svodAppSlice from '../components/svodApp/svodAppSlice';
import globalReducer from '~components/global/globalSlice';
import alertPopup from '~components/alertPopup/alertPopupSlice';

import { withReduxStateSync } from 'redux-state-sync';

const rootReducer = combineReducers({
  root,
  auth,
  home,
  alertModal,
  toast,
  landingPage,
  detailPage,
  box,
  loading,
  filter,
  backdrop,
  faq,
  tenantConfig,
  youtubePage,
  blockCountry,
  svodApp: svodAppSlice,
  history: historyReducer,
  global: globalReducer,
  alertPopup,
});

export default withReduxStateSync(rootReducer);
