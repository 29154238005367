import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Modal } from 'react-bootstrap';
import profilePageId from '../../../constants/ids/profilePageId';
import { getConfigByKey } from '../../tenantConfig/services';
import { setTermsAndConditions } from '~components/global/globalServices';

const termsOfUseLink = getConfigByKey('termsOfUseLink');

class TermOfUse extends Component {
  static propTypes = {
    modalShow: PropTypes.bool,
    showModal: PropTypes.func,
  };

  componentDidMount() {
    const { dpSetTermsAndConditions } = this.props;
    if (termsOfUseLink) {
      return;
    }
    dpSetTermsAndConditions();
  }

  _modalShow() {
    const { showModal } = this.props;
    showModal();
  }

  render() {
    if (termsOfUseLink) {
      return null;
    }
    const { modalShow, documents } = this.props;

    return (
      <Modal
        show={modalShow}
        onHide={() => this._modalShow()}
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton style={{ border: 'none' }} />
        <Modal.Body style={{ height: 700, overflow: 'auto' }}>
          <div
            style={{ padding: 50, paddingTop: 0 }}
            dangerouslySetInnerHTML={{ __html: documents.termsAndConditions.content || '' }}
          ></div>
        </Modal.Body>
      </Modal>
    );
  }
}

TermOfUse.defaultProps = {
  showModal: () => {},
  modalShow: false,
};

const mapDispatchToProps = {
  dpSetTermsAndConditions: () => setTermsAndConditions(),
};

const mapStateToProps = state => ({
  documents: state.global.documents,
});

export default connect(mapStateToProps, mapDispatchToProps)(TermOfUse);
