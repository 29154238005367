import { getConfigByKey } from '../../features/tenantConfig/services';

const ko = {
  translations: {
    locale: {
      vi: 'Việt Nam',
      en: 'English',
      ko: '한국인',
    },
    vietnam: `베트남어`,
    english: `영어`,
    formatDob: `dd/MM/yyyy`,
    premium: {
      on: `VIP 온`,
      off: `광고 끄기`,
    },
    monthly: `월`,
    invalid_phone_number: '전화번호가 유효하지 않습니다.',
    alertWrongEmailFormat: `이메일 형식이 올바르지 않습니다. 이메일을 다시 확인하고 다시 시도하십시오.`,
    alertNullEmailPass: `이메일과 비밀번호를 입력해주세요.`,
    ribbon: {
      view: `보다`,
      like: `처럼`,
      all: `모두 보기`,
      txtViewMore: `더보기`,
      slide: {
        txtWatchLive: '라이브 시청',
        txtMoreInfo: '더 많은 정보',
        watch: '지금보기',
      },
      removeFavorite: {
        txtTitle: `확실해?`,
        txtDes: `내 목록 페이지에서 이 콘텐츠를 제거합니다.`,
      },
      txtSelectCategory: `카테고리`,
    },
    navigation: {
      login: `로그인`,
      logout: `로그아웃`,
      profile: `프로필`,
      search: `찾다`,
      account: `계정`,
    },
    search: {
      message: `검색 키워드에 대한 결과가 없습니다`,
      result: `검색 키워드가 "{{name}}" 인 결과가 {{number}} 개 있습니다.`,
      tags: `태그`,
      hint: `추천 - 같은 카테고리`,
      movie: `뜨거운 영화`,
    },
    popupMessage: {
      cancelPlan: {
        title: `현재 구독을 취소하시겠습니까?`,
        description: `취소 후에도 {{daytime}} 까지 서비스를 계속 이용하실 수 있습니다.`,
        btnNo: `아니요. 잘못 클릭했습니다.`,
        btnYes: `예. 취소하고 싶어요!`,
      },
      cancelPlanSuccess: {
        title: `구독을 성공적으로 취소했습니다.`,
        description: `구독이 취소되었으며 {{daytime}} 에 만료됩니다. ${getConfigByKey(
          'name',
        )} 를 사용해 주셔서 감사합니다!`,
      },
      payment: {
        title: `결제 체크아웃`,
        service: `서비스`,
        CardInfo: `체크/신용카드 정보`,
        autoActive: `매월 자동 갱신`,
        buttonCheckOut: `체크아웃 \${{money}}`,
        txtTitlePaymentSuccess: `거래가 완료되었습니다`,
        txtDesPaymentSuccess: `구독이 {{name}}(으)로 성공적으로 변경되었습니다.`,
        month: `월`,
        tháng: `월`,
      },
      messageEmailSucces: {
        description1: `확인 이메일이 귀하의 사서함으로 전송되었습니다.`,
        description2: `메일함에서 확인 이메일을 찾아 확인 링크를 클릭하여 이메일을 확인하세요.`,
        description3: `귀하의 이메일은 다음 이후에만 변경됩니다.`,
        description4: `새 이메일을 확인했습니다.`,
      },
      buttonYes: `예`,
      buttonNo: `아니요`,
      buttonExit: `닫다`,
      buttonOkie: `좋아요`,
      updateSubscriptionSuccess: `업데이트 성공!`,
      desscriptionUpdateSubscriptionSuccess: `결제가 완료되고 구독이 업그레이드되었으며 즉시 적용됩니다.`,
    },
    loginScreen: {
      title: `계속하려면 로그인하세요.`,
      ondemandviet: `${getConfigByKey('name')}`,
      Or: `또는`,
      placeholderEmail: {
        default: `귀하의 이메일`,
        odv: `귀하의 이메일`,
        sctv: '이메일 또는 전화번호',
        projectw: `귀하의 이메일`,
      },
      placeholderPassword: `너의 비밀번호`,
      textNotAccount: `아직 계정이 없으신가요?`,
      buttonTextRegis: `새 계정 등록`,
      buttonTextForgotPass: `비밀번호를 잊어 버렸습니까?`,
      buttonTextSignIn: `로그인`,
      buttonContinueFb: `페이스북으로 로그인`,
      buttonTextSignInPhone: `전화번호로 로그인`,
      buttonTextSignUpFacebook: `페이스북으로 가입하기`,
      buttonTextSignInFacebook: `페이스북으로 로그인`,
      buttonTextSignInGoogle: `Google을 통해 로그인`,
      buttonTextForgotPassword: `비밀번호를 잊어 버렸습니까?`,
      buttonTextSignUp: `새 계정 등록`,
      loginFBErrorMessage: `페이스북 로그인에 문제가 있습니다. 다시 시도해 주세요.`,
      alertLoginFail: `로그인하는 동안 몇 가지 문제가 있습니다. 다시 시도해 주세요.`,
      alertNetWorkError: `시스템에 연결하는 동안 몇 가지 문제가 있습니다. 다시 시도하십시오.`,
      cbRememberMe: `날 기억해`,
    },
    registerScreen: {
      gender: {
        male: `남성`,
        female: `여자`,
        underfind: `대답하지 않는 것을 선호`,
      },
      signUp: `가입하기`,
      signIn: `로그인`,
      txtName: `이름`,
      txtRePassword: `비밀번호를 다시 입력하세요`,
      titleRegis: `이메일로 등록`,
      titleBinding: `(*)가 있는 필드는 필수 항목입니다.`,
      placeholderEmail: `이메일 `,
      placeholderFirstName: `성 `,
      placeholderLastName: `이름 `,
      placeholderPassword: `비밀번호 `,
      placeholderPhone: `전화 번호 `,
      placeholderName: `이름 `,
      placeholderGender: `성별 `,
      placeholderBirthday: `생일 `,
      buttonTextSignUp: `등록하다`,
      buttonTextSignIn: `로그인`,
      textHaveAccount: `이미 회원이신가요?`,
      textSucces: `새 계정을 성공적으로 등록했습니다.`,
      textLogin1: `축하합니다! 제발 `,
      login: `로그인 `,
      textLogin2: `서비스를 계속 사용하기 위해.`,
      alertPassLength: `비밀번호는 5자 이상이어야 합니다.`,
      alertNull: `등록 정보는 필수 항목입니다. 정보를 입력하세요.`,
      alertSuccess: `새 계정을 성공적으로 등록했습니다. 메일함을 확인하여 계정 이메일을 확인하십시오.`,
      alertError: `새 계정을 등록하는 동안 몇 가지 문제가 있습니다. 확인하고 다시 시도하십시오.`,
      lblBirthday: `생일`,
      msgAgeWarning: `* 가입하려면 13세 이상이어야 합니다.`,
    },
    profile: {
      overview: `계정 개요`,
      brief: `프로필`,
      authEmail: `이메일이 확인되지 않았습니다. 서비스를 계속 사용하려면 메일함으로 이동하여 이메일을 확인하세요.`,
      change: `변화`,
      cancelPlan: `구독 취소`,
      'number account': `계정 코드`,
      name: `이름`,
      email: `이메일`,
      're email': `확인 이메일 재전송`,
      dob: `생일`,
      phone: `전화 번호`,
      sex: `성별`,
      'service pack': `구독`,
      'privacy policy': `개인 정보 정책`,
      'box streaming': `이용약관`,
      'otp odv': `${getConfigByKey('short_name')} TV Box의 로그인 코드`,
      'countdown time': `이후 자동으로 새로고침 `,
      second: `seconds`,
      'auth otp odv': `에 대한 로그인 코드는 빠르게 로그인/활성화하는 데 사용됩니다. `,
      odv: `${getConfigByKey('short_name')} TV 박스`,
      'auto plan': `자동 갱신 날짜`,
      'cancel plan': `구독이 취소되었으며 다음 날짜에 만료됩니다.`,
      'not registration plan': `(당신은 구독하지 않았습니다)`,
      activated: `활성화됨`,
      notUpdate: `업데이트되지 않음`,
      accountFree: `무료 계정`,
      accountNoStb: `{{name}} 계정`,
      detailAccFree: `무료 계정은 ${getConfigByKey(
        'short_name',
      )} TV Box에 로그인/활성화하는 데 사용할 수 없습니다. ${getConfigByKey(
        'short_name',
      )} TV Box의 로그인 코드를 받으려면 구독하십시오.`,
      detailAccNostb: `{{name}} 계정은 ${getConfigByKey(
        'short_name',
      )} TV Box에 로그인/활성화하는 데 사용할 수 없습니다. ${getConfigByKey(
        'short_name',
      )} TV Box의 로그인 코드를 받으려면 구독하십시오.`,
      buttonRegPlan: `구독 업그레이드`,
      navigation: {
        overView: `프로필`,
        updateInfo: `프로필 업데이트`,
        upgratePlan: `구독 변경`,
        transaction: `결제 내역`,
        changePass: `비밀번호 변경`,
        contactUs: `문의하기`,
        paymentInformation: `신용 카드 정보`,
      },
    },
    changeInformation: {
      updateAccount: `정보 업데이트`,
      email: `이메일`,
      brief: `프로필`,
      password: `비밀번호`,
      phone: `전화 번호`,
      name: `이름`,
      firstName: `이름`,
      lastName: `성`,
      gender: `성별`,
      changeEmail: `이메일을 바꾸다`,
      dob: `생일`,
      loginFb: `YFacebook을 통해 로그인하지 않았습니다.`,
      male: `남성`,
      female: `여자`,
      another: `대답하지 않는 것을 선호`,
      select: `성별을 선택하세요...`,
      buttonSubmit: `제출하다`,
      changeEmailScreen: {
        buttonChange: `업데이트`,
        newEmail: `새 이메일을 입력하세요`,
        reNewEmail: `새 이메일 다시 입력`,
        warring: `* 이메일은 계정 로그인에 사용됩니다. 이메일을 변경한 후 다음 로그인에 이 새 이메일을 사용해야 하며 이전 이메일은 유효하지 않습니다.`,
      },
      alert: {
        nullEmail: `이메일을 입력하고 확인하고 다시 시도하십시오.`,
        validEmail: `이메일 형식이 올바르지 않습니다. 확인하고 다시 시도하십시오.`,
        wrongEmail: `이메일이 유효하지 않습니다. 확인하고 다시 시도하십시오.`,
        nullFirstName: `이름을 2자 이상 입력하고 확인하고 다시 시도하십시오.`,
        nullLastName: `성을 2자 이상 입력하고 확인 후 다시 시도해 주세요.`,
        nullbod: `당신의 생일을 입력하십시오`,
        nullgender: `당신의 성별을 선택 해주세요`,
        validbod: `생일이 잘못되었습니다. 확인하고 다시 시도해 주세요.`,
        validPhone: `전화번호가 올바르지 않습니다. 확인하고 다시 시도하십시오.`,
        messageSucces: `귀하의 정보가 성공적으로 변경되었습니다.`,
      },
    },
    transactionHistory: {
      bill: `송장`,
      myPlan: `귀하의 구독`,
      cancelPlan: `취소되었으며 다음 날짜에 만료됩니다.`,
      autoPlan: `자동 갱신 날짜`,
      timeUse: `사용 시간`,
      cancel: `구독 취소 {{name}}`,
      description: `설명`,
      odv: `${getConfigByKey('short_name')} TV 박스`,
      total: `총`,
      durationDay: `{{periodStart}}부터 {{periodEnd}}까지의 구독 {{name}}`,
      txtBuyBox: `STB 가격(일회성)`,
      txtRentBox: `STB 월세`,
      freeAccount: `무료 계정`,
      servicePackageUpgrade: '업그레이드 구독',
      package: `패키지`,
    },
    availableplan: {
      plan: `구독 계획`,
      buttonUpdate: `구독 계획 업데이트`,
      countDownTime: ` 남은 평가판 일수`,
      MisscountDownTime: '',
      autoActive: `자동 월간 갱신`,
      alertPopup: {
        txtTitle: `선택한 서비스 패키지에는 TiVi ${getConfigByKey(
          'short_name',
        )} 상자가 필요합니다.`,
        btnHaveBox: `이미 ${getConfigByKey('name')} TV Box가 있습니다.`,
        btnHaveNotBox: `${getConfigByKey('name')} TV Box가 필요합니다`,
      },
      promoZero: `{{discount}} 할인 프로모션 코드는 {timesApplied} 다음 결제에 적용됩니다.`,
      willArrive: `${getConfigByKey('name')} TV 박스는 가입 후 7일 이내에 도착합니다.`,
    },
    forgotPasswordScreen: {
      txtForgot: `비밀번호를 잊어 버렸습니까?`,
      placeholderEmail: `이메일`,
      placeholderCode: `확인 코드`,
      placeholderNewPass: `새 비밀번호`,
      buttonTextSignIn: `확인하다`,
      buttonTextReSendCode: `확인 코드 재전송`,
      alertMessFailCode: `잘못된 확인 코드`,
      alertMessFailEmail: `이메일이 존재하지 않습니다.`,
      alertMessIsvalidEmail: `이메일이 유효하지 않습니다.`,
      alertMessSendLink: `비밀번호 변경을 위한 이메일이 귀하의 {{email}} 편지함으로 발송되었습니다. 이메일을 확인하여 비밀번호 변경 확인 링크를 받으십시오.`,
      alertNullEmail: `이메일을 입력하세요`,
      alertNullCodePass: `새 비밀번호와 확인 코드를 입력하세요.`,
      alertSuccess: `새 비밀번호가 성공적으로 변경되었습니다`,
      alertError: `이메일이 존재하지 않습니다. 확인하고 다시 시도하십시오.`,
      confirmation: `비밀번호 변경`,
      messageChange: `서비스를 계속 사용하려면 비밀번호를 변경하세요.`,
      'sucsses confirmation': `비밀번호를 성공적으로 변경했습니다`,
      'message login': `서비스를 계속 사용하려면 로그인하세요.`,
      're login': `재 로그인`,
      'new password': `새 비밀번호 :`,
      're password': `새 비밀번호를 다시 입력했습니다. :`,
      submit: `제출하다`,
      alert: {
        wrong: `새 암호는 다시 입력한 새 암호와 다릅니다. 확인하고 다시 시도하십시오.`,
        wrongs: `비밀번호가 재입력한 비밀번호와 일치하지 않습니다. 확인 후 다시 시도해 주세요.`,
        valid: `비밀번호 길이는 6자 이상이어야 합니다.`,
        null: `비밀번호를 입력하세요`,
        exit: `출구`,
      },
      forgotPassword: `비밀번호를 잊으 셨나요`,
      titleForgot: `등록할 때 사용한 이메일을 입력합니다. 비밀번호 재설정을 위한 인증 코드가 포함된 이메일을 보내드립니다.`,
      emailAddress: `이메일`,
      buttonTextSent: `보내다`,
      supportEmail: `추가 지원이 필요하면 다음으로 고객 서비스에 문의하십시오. `,
    },
    changePasswordScreen: {
      updatePass: `비밀번호 변경`,
      placeholderOldPassword: `기존 비밀번호`,
      placeholderPassword: `새 비밀번호`,
      rePlaceholderPassword: `새 비밀번호를 다시 입력했습니다.`,
      buttonTextSignIn: `확인하다`,
      alertSuccess: `비밀번호가 성공적으로 변경되었습니다.`,
      alertPassLength: `암호 길이는 6보다 크거나 같아야 합니다.`,
      alertProvideInfomation: `모든 정보를 기입해주세요`,
      alertErrorNewPass: `"새 암호"는 "새 암호를 다시 입력했습니다"와 다릅니다. 확인하고 다시 시도하십시오.`,
      alertErrorOldPass: `이전 비밀번호가 올바르지 않습니다. 확인하고 다시 시도해 주세요.`,
    },
    contactUs: {
      txtTitle: `문의하기`,
      txtSmallTitle: `피드백을 보내주세요`,
      labelForm: `이메일 문의`,
      txtHelp: `일일 작업 시간은 <span>오전 9시</span>부터 <span>오후 6시</span> <span>태평양 표준시(태평양 표준시)</span>입니다.
      월요일부터 금요일까지. 근무 시간 외 연락처 및 피드백
      다음 영업일에 진행 및 회신됩니다.
      감사합니다.`,
      subjectLbl: `주제`,
      commentLbl: `콘텐츠`,
      submitBtn: `제출하다`,
      txtContentSubject: `{{current_package_name}}에서 {{new_package_name}}(으)로 다운그레이드를 요청하고 상자를 반환합니다. 내 계정 {{account_code}}/{{account_email}} 패키지를 다운그레이드하고 싶습니다.`,
      txtContent: `내 계정 {{account_code}}/{{account_email}} 패키지를 {{current_package_name}}에서 {{new_package_name}}(으)로 다운그레이드하고 임대 중인 상자를 반품하고 싶습니다.`,
      placeholder: {
        email: `이메일`,
        name: `이름`,
        subject: `주제`,
        comment: `콘텐츠`,
        txtContactName: `담당자 이름`,
      },
      alert: {
        nullEmail: `이메일을 입력하세요`,
        validEmail: `이메일 형식이 유효하지 않습니다`,
        nullName: `당신의 이름을 입력하세요`,
        nullSubject: `제목을 입력하세요`,
        nullComment: `피드백 내용을 입력하세요.`,
        messageSucces: `저희에게 연락해 줘서 고마워요. 최대한 빨리 연락드리겠습니다!`,
      },
      popup: {
        txtTitle: `선택한 서비스 요금제가 현재 요금제보다 낮습니다.`,
        txtDes: `<b> ${getConfigByKey(
          'short_name',
        )} TV 박스 </b>를 반품하려면 고객 서비스에 문의하십시오.`,
        txtPhone: `${getConfigByKey('phone_number_1')}`,
        txtOr: `또는`,
        btnSubmit: `보내다`,
      },
    },
    faq: {
      txtQuestion: `자주 묻는 질문`,
      txtSub: `문제를 더 빨리 해결하는 데 도움이 될 수 있습니다.`,
    },
    landingPage: {
      watch: `지금보기`,
      'continue watching': `재개하다`,
      'episodes season': `Eps {{episodes}} - 시즌 {{season}}`,
      episodes: `Eps {{에피소드}}`,
      TxtTotalEps: `{{에피소드}} Eps`,
      buttonUpgrade: `시청하기`,
      buttonLogin: `시청하려면 로그인`,
      btnLogin: `로그인`,
      directors: `이사: `,
      author: `작가: `,
      updating: `업데이트 중`,
      actor: `배우: `,
      day: ` 일 `,
      month: ` 월 `,
      movie: `영화`,
      addList: `내 목록에 추가`,
      removeList: `내 목록에서 제거`,
      min: ` m | `,
      duration: `지속: `,
      recommended: {
        recommended: ` 관련 내용`,
      },
      alert: {
        txtTitle: `VIP컨텐츠입니다. 시청하려면 계정을 업그레이드하세요.`,
        btnUpgrade: `업그레이드`,
        btnCancel: `취소`,
      },
      replayShow: `Eps 1부터 다시보기`,
      replayMovie: `재시작 `,
      season: {
        txtSort: `종류`,
        txtNewEps: `최신`,
        txtOldestEps: `가장 오래된`,
      },
    },
    box: {
      alertNullEmail: `이메일을 입력하세요`,
      alertWrongEmailFormat: `이메일 형식이 잘못되었습니다.. `,
      'unlimited movies': `무제한 영화,`,
      ondeman: `${getConfigByKey('sologan')}`,
      tvShow: `TV 쇼 등.`,
      watch: `어디서나 시청하세요. 언제든지 취소하세요.`,
      'ready to watch': `시청할 준비가 되셨나요? 이메일을 입력하여 멤버십을 생성하거나 다시 시작하세요.`,
      'join now': `지금 가입하세요`,
      sorry: `죄송합니다. 귀하의 국가에서는 아직 ${getConfigByKey(
        'name',
      )}을(를) 사용할 수 없습니다.`,
      txtBlockContrySubscription: `죄송합니다. 귀하의 국가에서는 이 서비스 팩을 사용할 수 없습니다.`,
      txtHome: `집`,
      titlePlan: `구독 계획`,
      btnContinue: `무료 구독 계속하기`,
      txtAds: `광고 및 제한된 콘텐츠 포함`,
      subscriptionPlanListShortDescription: {
        default: `나에게 맞는 플랜을 선택하세요. 모든 계획에는 14일 무료 평가판이 포함됩니다.`,
        sctv: `나에게 맞는 플랜을 선택하세요.`,
      },
    },
    device: {
      txtTitle: `장치 가용성`,
      txtDes: `구독 옵션을 선택하세요 `,
      txtNotBox: `${getConfigByKey('name')} TV 박스가 필요합니다`,
      txtInfomation: `${getConfigByKey('name')} TV가 필요합니다`,
      txtHasBox: `이미 장치가 있음`,
      txtHasBoxinfo: `아래의 모든 기기에서 ${getConfigByKey('name')}를 구독할 수 있습니다.`,
      txtSelect1: `${getConfigByKey('name')} TV 박스 `,
      txtSelect2: `Chromecast with Google TV `,
      txtSelect3: `안드로이드 TV`,
      btnContinue: `계속하기`,
    },
    boxDetail: {
      title: `${getConfigByKey('short_name')} TV BOX 구매`,
      'buy now': ` 지금 구매`,
      'free 30 day': `30일 동안 무료 평가판`,
      'auto 30 day': `자동 지불 및 자동 갱신`,
      'confirm & payment': `확인 및 결제`,
      total: `총`,
      box: {
        tv: `1 x ${getConfigByKey('name')} TV 박스`,
        remote: `1 x IR 원격 제어 `,
        power: `1 x 전원 어댑터 `,
        hdmi: `1 x HDMI 케이블`,
        pin: `2 x AAA 배터리`,
        user: `1 x 사용자 설명서`,
      },
      'TV Box': `${getConfigByKey('name')} 셋톱 TV 박스`,
      referralPrice: `인트로 가격`,
      return30day: `30일 반품/교환`,
      onlyOne: `일회성 결제`,
      quantity: `수량`,
      requireDescription: `* 평가판 기간 후에도 이 구독을 계속 사용하면 TV Box를 계속 사용할 수 있습니다.`,
      requireDescription2: `평가판 기간 후에 구독을 취소하는 경우 TV Box에 대해 $50를 지불하지 않아도 되도록 TV Box를 반환해야 합니다.`,
    },
    boxLogin: {
      'login/register': `로그인/등록`,
      email: `이메일`,
      password: `비밀번호`,
      repassword: `비밀번호 확인`,
      forgotpass: `비밀번호를 잊으 셨나요 ?`,
      'login with facebook': `페이스북으로 로그인`,
      alertNullEmail: `이메일을 입력하세요`,
      alertNullPass: `비밀번호를 입력하세요`,
      alertValidEmail: `이메일 형식이 올바르지 않습니다. 확인하고 다시 시도하십시오.`,
      login: `로그인`,
      register: `등록하다`,
      txtCheckbox: `계정을 만들면 이에 동의합니다.`,
      txtAnd: `그리고`,
    },
    boxConfirm: {
      txtTitleBox: {
        default: `거래가 완료되었습니다`,
        sctv: `거래가 완료되었습니다`,
      },
      txtTitleFree: {
        default: `등록 완료`,
        sctv: '등록 완료',
      },
      description1: `이메일을 확인하기 위해 이메일이 사서함으로 전송되었습니다.`,
      description2: ` ${getConfigByKey('name')} 사용을 시작하려면 이메일을 확인하세요.`,
      actived: `이메일이 확인되면 계속하십시오.`,
      txtGoHome: `계속해서 홈 페이지로 돌아가기`,
      getCodeOtp: `PIN 코드 받기`,
      sentEmail: `여전히 이메일을 찾을 수 없습니까?`,
      reSentEmail: `확인 이메일을 다시 보내`,
      continue: `시청 시작`,
      txtNeedHelp: `도움이 필요하다?`,
      txtContactUs: `문의하기`,
    },
    subscriptions: {
      paypal: {
        txtTitlePaypal: `결제는 PayPal에서 처리합니다. <br /> PayPal 결제가 완료되면 이 페이지로 다시 리디렉션됩니다.`,
        btnComplete: 'PayPal로 구매 완료',
        btnBackHomePage: '홈페이지로 돌아 가기',
        btnTryAgain: '다시 할',
        txtSuccess: '거래가 완료되었습니다',
        txtSuccess2: '재미있는 프로그램을 계속 보려면 홈페이지로 돌아가세요.',
        txtFail: '결제 실패',
        txtFail2: '체크아웃에 오류가 있었을 수 있습니까? 다시 실행하시겠습니까?',
      },
      bootpay: {
        txtTitleBootpay: `결제는 Bootpay에서 처리합니다. <br /> Bootpay 결제가 완료되면 이 페이지로 다시 리디렉션됩니다.`,
        btnComplete: 'Bootpay로 구매 완료',
      },
      footer: {
        'privacy policy': `개인 정보 정책`,
        'box streaming': `이용약관`,
        termsOfUse: `이용약관`,
        ContactUs: `문의하기`,
        doNotSell: `내 정보를 판매하지 마십시오`,
        phone: `${getConfigByKey('phone_number_1')}`,
        email: `{{email}}`,
        copyright: `Copyright 2020 (주)오디케이미디어`,
        txtFaq: `FAQs`,
      },
      'for web': `웹용`,
      'comming soon': ` 출시 예정`,
      'subscribe here': `여기에서 구독`,
      'sign in': `로그인`,
      profile: `프로필로 이동`,
      month: `m`,
      tháng: `m`,
      year: `년도`,
      năm: `년도`,
      'privacy policy': `개인 정보 정책`,
      'box streaming': `이용약관`,
      'finish and pay': `완료하고 지불`,
      dayTrial: `{{day}}일 동안 무료`,
      'Debit/ credit card': `체크/신용카드`,
      'Card number': `카드 번호`,
      Expory: `만료`,
      titleUser: `${getConfigByKey('short_name')} TV Box를 보낼 수 있도록 아래 정보를 입력하세요.`,
      name: `이름 `,
      address: `주소 `,
      apt: `아파트, 스위트, 유닛(옵션) `,
      city: `도시 ( 산호세 , 웨스트민스터 , 로스앤젤레스 , ... )`,
      zip: `우편 번호 `,
      state: `상태 `,
      btnSave: `저장하고 계속하기`,
      btnEdit: `편집하다`,
      alertnullName: `전체 이름을 2자 이상 입력하세요.`,
      alertnullAddress: `주소를 입력하세요`,
      alertnullCity: `귀하의 도시를 입력하십시오`,
      alertnullApt: `Atp, Ste, Unit을 입력하세요.`,
      alertnullZipCode: `당신의 우편 번호를 입력 해주세요`,
      alertnullstate: `귀하의 상태를 입력하십시오`,
      alertSucessPay: `결제에 성공했습니다. 구독이 업그레이드되었습니다.`,
      alertValidTitle: `체크/신용카드 정보를 입력하세요.`,
      alertmostName: `전체 이름을 최대 47자 이내로 입력하십시오.`,
      alertmostAddress: `최대 64자 이내로 주소를 입력하세요.`,
      alertmostApt: `Apt, Ste, Unit을 최대 6자 이내로 입력하세요.`,
      alertmostZipCode: `우편번호는 최대 5자 이내로 입력하세요.`,
      alertmostState: `50자 이내로 상태를 입력하세요.`,
      alertmostCity: `도시를 최대 50자 이내로 입력하십시오.`,
      alertErrorNet: `인터넷에 연결하는 동안 일부 정보가 있습니다. 확인하고 다시 시도하십시오.`,
      percentageOff: `{{percentage}}% 할인`,
      viewDetail: {
        odv: `무료 평가판 시작`,
        sctv: `패키지 가입`,
      },
      txtChangePlans: `{{name}}(으)로 변경`,
      txtplanActived: `현재 구독`,
      txtService: `서비스 `,
      titleCheckout: `점검`,
      txtShippingAddress: `배송 주소`,
      txtOrderSummary: `주문 요약`,
      txtTotal: `총`,
      placeholderCouponCode: `쿠폰 코드`,
      btnApplyCoupon: `적용하다`,
      txtPayment: `지불 세부 사항`,
      lblCardHolderName: `카드 소지자 이름`,
      txtWhatsCVC: `CVC는 무엇입니까?`,
      txtCVCDescription: `CVC는 카드 앞면 또는 뒷면에 있는 3-4자리 코드를 나타냅니다.`,
      txtTermsOfUse: `이용약관`,
      txtPrivacyPolicy: `개인 정보 정책`,
      txtAnd: `그리고`,
      txtCompletePurchase: `구매 완료`,
      txtDiscount: `할인`,
      txtSubtotal: `소계`,
      txtPlanTrial: `{{plans}} 평가판`,
      txtOverAge: `나는 18세 이상이며 이에 동의합니다.`,
    },
    video: {
      subtitle: `자막 없음`,
      vietnam: `베트남어`,
      english: `영어`,
      eps: `삽화`,
      messagePopup: {
        txtWarning: `경고`,
        txtPolicy: `{{tenantSlug}}는 이 라이브 채널의 방송된 콘텐츠에 대해 책임을 지지 않습니다. 채널의 민감한 콘텐츠를 필터링하기 위해 최선을 다하고 있습니다.`,
        txtTitle: `내용이 제한적입니다`,
        txtDes: `이 프로그램을 계속 시청하려면 로그인이 필요합니다.`,
        txtDesHaveSupcription: `계속 시청하려면 서비스 팩을 구독하세요.`,
        btnUpgrade: `서비스 팩`,
      },
    },
    notification: {
      confirmSuccessEmail: `이메일 확인 성공`,
      selectDoneToContinueWithTheServiceExperience: `계속하려면 "마침"을 클릭하십시오.`,
      done: `Finish`,
      emailConfirmationFailed: `이메일 확인 실패`,
      anErrorOccurredWhileAuthenticatingTheEmail: `이메일을 확인하는 동안 몇 가지 정보가 있습니다. 다시 시도해 주세요.`,
    },
    myList: {
      txtTitle: `나의 목록`,
      txtNullData: `목록에 추가한 콘텐츠가 없습니다.`,
    },
    report: {
      txtReport: `보고서`,
      txtTitle: `오류 보고서`,
      txtDescription1: `이용에 불편을 드려 죄송합니다.`,
      txtDescription2: `제품 품질을 더 좋게 개선하려면 아래에 제안된 오류 보고서를 선택하여 더 빨리 수정할 수 있도록 도와주세요.`,
      btnSend: `오류 보고서를 보내다`,
      txtDesComplete: `오류를 신고해 주셔서 감사합니다!`,
      txtTitleComplete: `오류 보고 완료`,
      btnComplete: `영화로 돌아가기`,
      btnInput: `이미지 업로드`,
      txtOrther: `기타/세부사항`,
      txtErrorFile: `선택한 파일이 너무 큽니다. 최대 크기는 10MB입니다.`,
      txtErrorSpace: `공백은 허용하지 않습니다`,
      txtErrorAcc: `로그인 해주세요!`,
      placeholder: {
        txtComment: `설명 쓰기`,
        txtFile: `입력 파일`,
      },
    },
    update: `업데이트`,
    cancel: `취소`,
    delete: `좋아요`,
    creditDebitCard: `신용/직불카드`,
    txtPalpalPortal: `온라인 거래 포털`,
    expire: `내쉬다`,
    updateCard: `카드 업데이트`,
    removeCard: `카드 제거`,
    warningRemoveCard: `귀하의 카드는 계정에서 완전히 제거됩니다. 카드를 제거하시겠습니까?`,
    changePaymentInformation: `카드 정보 변경`,
    youAreNotConnectedToTheInternet: `인터넷에 연결되어 있지 않습니다`,
    selectBoxPage: {
      convenientWayToStream: `편리한 스트리밍 방법`,
      purchaseOnDemandVietTvBoxToday: `지금 ${getConfigByKey('name')} TV 박스를 구입하세요!`,
      onDemandVietTvBox: `${getConfigByKey('name')} TV 박스`,
      unit: `{{count}} 상자`,
      unit_plural: `{{count}} 상자`,
      buy: `구입`,
      purchaseOptions: `구매 옵션`,
      continue: `계속하기`,
      iAlreadyHaveAnOnDemandVietTvBoxToday: `이미 ${getConfigByKey('name')} TV Box가 있습니다.`,
      onDemandVietTvBoxIncludedARemoveControl: `이미 ${getConfigByKey('name')} TV Box가 있습니다.`,
      rent: `임차료`,
      lblBoxQuantity: `TV 박스 수량`,
      txtOneTimePayment: `일회성 결제`,
      txtAutoRenewal: `자동 갱신`,
      txtShortPerMonth: `/mo`,
    },
    announcement: {
      title: `발표`,
      txt1: `현재 방송 중인 콘텐츠는 민감하거나 정치적인 콘텐츠로 인해 차단되고 있습니다.`,
      txt2: `${getConfigByKey(
        'name',
      )}은(는) 라이브 채널 방송 시간과 예정된 방송 콘텐츠의 특성으로 인해 드라마 또는 프로그램의 일부를 차단하거나 차단하는 사이에서 균형을 유지하면서 완전한 시청자입니다.`,
      txt3: `베트남 방송센터 생중계로 인해 불쾌감을 주는 내용을 표시할 의도가 아니므로 민감한 내용 중 일부가 유출될 수 있는 점 양해 부탁드립니다.`,
      txt4: `${getConfigByKey(
        'name',
      )} 팀은 이해해 주시고 우리의 소중한 고객이 되어주셔서 진심으로 감사드립니다.`,
    },
    noBroadcastSchedule: `방송 일정 없음`,
    broadcastSchedule: `방송 일정`,
    rentBox: {
      questionMark: {
        txt1: `- 렌탈 기간 동안 오작동 하드웨어에 대한 무료 교체. 무료 배송 라벨은 고객 서비스에 문의하십시오.`,
        txt2: `- {{trialDays}}일은 평가판 기간 동안 수수료 없이 반환됩니다.`,
        txt3: `- 무료 배송.`,
      },
      refund: `{{trialDays}}일 평가판 기간 내에 전액 환불됩니다.`,
    },
    filter: {
      filterFilm: `필터 필름`,
      removeAll: `모두 제거`,
      removeFilter: `필터 제거`,
      regions: `지역`,
      genres: `장르`,
      languages: `현지화`,
      provider: `콘텐츠 제공자`,
      people: `배우/감독/프로듀서`,
      all: `모두`,
      txtFitlerNondata: `죄송합니다. 검색결과가 없습니다.`,
      txtFilterHistory: `필터 기록: `,
    },
    notDataToShow: `표시할 데이터가 없습니다.`,
    cancelSubscription: {
      survey: {
        sendSurvey: `설문조사 보내기`,
        back: `뒤`,
        title: `서비스 조사`,
        description1: `귀하의 결정에 대해 유감스럽게 생각합니다. 고객 경험을 개선하기 위해 서비스 사용을 중단하는 이유를 제공하세요.`,
        description2: `감사합니다!`,
        reason: `서비스 취소 사유: `,
        txtError1000: `1000자 이내로 입력하세요.`,
        txtSelect: `여러 질문을 선택할 수 있습니다.`,
        txtDes: `내용물`,
        txt1k: `1000자`,
      },
      confirm: {
        title: `서비스 취소`,
        description1: `우리 회사는 매일 제품 품질을 개선하기 위해 노력하고 있으며 몇 달 안에 귀하에게 적합한 콘텐츠와 새로운 기능 모두에서 개선된 것을 볼 수 있을 것입니다. 저희 회사의 발전에 함께 해주세요!`,
        description2: `서비스를 계속 사용하는 경우에만 제공되는 특별 혜택입니다.`,
        discount: `앞으로 6개월 동안 20% 할인 받기`,
        anotherTry: `한 번 더 해볼게`,
        no: `아니요... 도와드릴 수 없어 죄송합니다`,
      },
      agree: {
        title: `서비스 계속 사용`,
        description: `${getConfigByKey(
          'short_name',
        )}의 서비스를 계속 사용하기로 결정해 주셔서 감사합니다. 귀하의 의견은 가능한 한 빨리 검토 및 수정되어 귀하의 경험이 최상의 방식으로 개선될 수 있도록 하겠습니다.`,
        continue: `영화 계속 보기`,
      },
      completed: {
        title: `서비스 취소 완료`,
        description1: `아래의 "취소 완료"를 클릭하여 취소를 완료하십시오.`,
        description2: `- 취소는 현재 청구 기간이 종료되는 {{restTime}}에 유효합니다.`,
        description3: `- 언제든지 구독을 다시 활성화할 수 있습니다. 한편 제한된 콘텐츠로 ${getConfigByKey(
          'name',
        )} 를 계속 사용할 수 있습니다 ... `,
        thanks: `진심으로 감사드립니다!`,
        finish: `취소 완료`,
        back: `뒤`,
      },
    },
    cooldown: {
      program: `다음 프로그램`,
      nameEpg: `{{name}}`,
      timeStart: `에 시작됩니다: `,
      hours: `h`,
      minute: `m`,
      second: `s`,
    },
    recommedation: {
      txtComing: `{{name}}을(를) 시청했기 때문에`,
      txtStart: `다음 프로그램은 다음 시간 이후에 시작됩니다. `,
    },
    localizedMessages: {
      invalid_number: `카드 번호는 유효한 신용 카드 번호가 아닙니다.`,
      invalid_expiry_month: `카드의 만료 월이 잘못되었습니다.`,
      invalid_expiry_year: `카드의 만료 연도가 잘못되었습니다.`,
      invalid_cvc: `카드의 보안 코드가 잘못되었습니다.`,
      incorrect_number: `카드번호가 정확하지 않습니다.`,
      incomplete_number: `카드 번호가 불완전합니다.`,
      incomplete_cvc: `카드의 보안 코드가 불완전합니다.`,
      incomplete_expiry: `카드의 만료일이 불완전합니다.`,
      expired_card: `카드가 만료되었습니다.`,
      incorrect_cvc: `카드의 보안 코드가 올바르지 않습니다.`,
      incorrect_zip: `카드의 우편번호 확인에 실패했습니다.`,
      invalid_expiry_year_past: `카드의 만료 연도가 과거입니다.`,
      card_declined: `카드가 거부되었습니다.`,
      missing: `고객에게 청구되는 카드가 없습니다.`,
      processing_error: `카드를 처리하는 동안 오류가 발생했습니다.`,
      invalid_request_error: `이 결제를 처리할 수 없습니다. 다시 시도하거나 다른 방법을 사용하십시오.`,
      invalid_sofort_country: `청구서 수신 국가는 SOFORT에서 허용되지 않습니다. 다른 국가를 시도하십시오.`,
      email_invalid: `이메일 주소가 잘못되었습니다. 수정하고 다시 시도하십시오.`,
    },
    player: {
      txtMind: `부인 성명: `,
      txtContent: `이 카테고리의 동영상은 YouTube에서 직접 가져온 것입니다. <b>{{tenantSlug}}</b>는 단순히 사용자를 원본 콘텐츠 소스에 연결하는 다리 역할을 하며 정당한 소유자입니다. YouTube에서 직접 광고를 볼 수 있습니다.`,
      blockAds: {
        txtTitle: `P임대 광고 차단기를 해제`,
        txtDes: `광고가 있는 무료 콘텐츠만 시청할 수 있습니다. 계속 시청하려면 브라우저의 광고 차단기 플러그인을 끄세요.`,
        txtButton: `광고 차단기를 끄는 방법에 대한 안내`,
      },
      warning: {
        txtHeader: '경고',
        txtTitle: '성인 만',
        txtDescription:
          '이것은 노골적이고 폭력적인 자료이며 18세 미만의 미성년자는 볼 수 없음을 알려드립니다. 법적 연령이 아닌 경우 즉시 이 페이지를 떠나십시오.',
        txtContentUnConfirm: '이 페이지를 남겨',
        txtContentConfirm: `18살이 넘었어요`,
      },
    },
    youtube: {
      txtRemoveTitle: `이 콘텐츠를 시청 목록에서 삭제하시겠습니까?`,
      txtRemoveDescription: `중단한 부분부터 계속 시청할 수 없습니다.`,
      btnCancel: `취소`,
      btnRemove: `확인`,
    },
    share: {
      txtCopy: `복사`,
      txtCopied: `복사됨`,
      txtShare: `공유하다`,
    },
    continue: '계속하기',
    payperview: {
      day: '일간',
      hour: '시간',
      hours: '시간',
      movie: '영화',
      show: '보여 주다',
      plan: {
        month: {
          txtPlan: '월간 구독 구매',
          txtDes: '제한 없이 모든 콘텐츠 보기',
          txtOption1: '14일 동안 무료 평가판',
          txtOption2: '17,000시간 이상의 프리미엄 콘텐츠',
          btn: '세부 정보보기',
        },
        views: {
          txtBuyDrama: '쇼 구매',
          txtBuyMovie: '영화 구매',
          timePaid: `구매후 {{timePaid}}{{unit}} 시청 가능`,
          btn: '계속하기',
        },
      },
      success: {
        txtTitle: '거래가 완료되었습니다',
        txtDesDrama: `프로그램을 성공적으로 구매하셨습니다.<br /> 이 프로그램을 시청할 수 있는 시간은 {{timePaid}} {{unit}}입니다.`,
        txtDesMovie: `영화를 성공적으로 구매하셨습니다.<br /> 이 영화를 볼 수 있는 시간은 {{timePaid}} {{unit}}입니다.`,
        btn: '계속하기',
      },
    },
    footer: {
      home: '집',
      products: '제품',
      company: '회사',
      blog: '블로그',
    },
    'Không có': '없는',
    Remove: '제거하다',
    ppvDescription:
      '라이브 콘텐츠를 제외한 주문형 콘텐츠는 결제 즉시 시청 가능하며 결제 완료 후 환불은 지원하지 않습니다.',
    ppvRefundCondition:
      '콘텐츠는 구매 완료 후 즉시 시청 가능하며 구매 취소를 원할 시 시청내역이 없는 경우에만 환불해 드립니다.환불과 관련하여 자세한 내용은 이용약관을 참고 부탁드립니다.',
  },
};

export default ko;
