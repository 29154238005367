import * as types from './actionTypes';

const initialState = {
  detail: {},
  season: {},
  caches: {
    progress: null,
  },
};

export default function landingPage(state = initialState, action = {}) {
  switch (action.type) {
    case types.UPDATE_LANDING_PAGE: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case types.REINIT_LANDING_PAGE: {
      return {
        ...state,
        detail: {},
        season: {},
      };
    }
    case types.UPDATE_SEASON_LANDING_PAGE: {
      return {
        ...state,
        season: { ...action.payload },
      };
    }
    case types.UPDATE_DETAIL_LANDING_PAGE: {
      return {
        ...state,
        detail: {
          ...state.detail,
          ...action.payload,
        },
      };
    }
    case types.SET_PROGRESS_CACHE: {
      if (!action?.payload?.id) {
        return state;
      }
      return {
        ...state,
        caches: {
          ...state.caches,
          progress: {
            [action?.payload?.id]: {
              value: action?.payload?.progress,
              expireTime: new Date().getTime() + 30 * 1000,
            },
          },
        },
      };
    }
    default:
      return state;
  }
}
