export const ODV = 'odv';
export const SCTV = 'sctv';
export const ESCONDIDO = 'escondido';
export const PROJECTW = 'projectw';

export const TENANT_NAME_ENUM = Object.freeze({
  ODV: ODV,
  SCTV: SCTV,
  ESCONDIDO: ESCONDIDO,
  PROJECTW: PROJECTW,
});
