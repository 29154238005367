import React, { Component } from 'react';
import { RotateLoader } from 'react-spinners';

export default class Spinner extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return <RotateLoader {...this.props} />;
  }
}
