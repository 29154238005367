import { createSlice } from '@reduxjs/toolkit';

const backdropSlice = createSlice({
  name: 'backdrop',
  initialState: {
    count: 0,
  },
  reducers: {
    isLoading(state, action) {
      const value = action.payload ? 1 : -1;
      state.count = state.count === 0 && value < 0 ? 0 : state.count + value;
    },
  },
});

export const { isLoading } = backdropSlice.actions;

export default backdropSlice.reducer;
