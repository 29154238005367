import { createStore, applyMiddleware, compose } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';
import { createMigrate, persistStore, persistReducer } from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import storage from 'redux-persist/lib/storage';
import rootReducer from './rootReducer';
import { VERSION, DEBUG } from '../constants/envConfig';
import { createStateSyncMiddleware, initMessageListener } from 'redux-state-sync';
import { LOGIN_SUCCESS, SIGNOUT_SUCCESS } from '~features/auth/actionTypes';
import { onLoginLogout } from '~features/auth/reducers';

const version = VERSION.split('.').pop() || -1;

const loggerMiddleware = createLogger({
  predicate: (getState, action) => DEBUG === 'true',
});
const migrations = {};
migrations[version] = state => {
  // migration to keep only auth state
  return {
    auth: state.auth,
  };
};
const config = {
  key: 'root',
  storage: storage,
  stateReconciler: autoMergeLevel2,
  blacklist: ['backdrop', 'tenantConfigs', 'blockCountry', 'global', 'alertPopup'],
  version: version,
  migrate: createMigrate(migrations, { debug: false }),
};

const configStateSync = {
  whitelist: [SIGNOUT_SUCCESS, LOGIN_SUCCESS],
};

const persistedReducer = persistReducer(config, rootReducer);
const initState = window.__STATE__ || {};
if (!DEBUG) {
  delete window.__STATE__;
}

const middlewares = [thunkMiddleware, loggerMiddleware, createStateSyncMiddleware(configStateSync)];

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
export const store = createStore(
  persistedReducer,
  initState,
  composeEnhancers(applyMiddleware(...middlewares)),
);

store.subscribe(() => onLoginLogout(store.getState()));

export const persistor = persistStore(store);
