import * as types from './actionTypes';

const initialState = {
  playerDisclaimer: 0,
  player: {
    volume: null,
    currentLink: null,
    isRestrictions: false,
    curatedLive: {},
    drm: [],
  },
};

export default function detailPage(state = initialState, action = {}) {
  switch (action.type) {
    case types.UPDATE_DETAIL_PAGE: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case types.GET_DETAIL: {
      return {
        ...state,
        ...action.detail,
      };
    }
    case types.UPDATE_DISCLAIMER: {
      return {
        ...state,
        playerDisclaimer: action.value,
      };
    }
    case types.UPDATE_EPGLIST: {
      return {
        ...state,
        epglist: action.payload,
      };
    }
    case types.SET_VOLUME: {
      return {
        ...state,
        player: {
          ...state.player,
          volume: action.payload,
        },
      };
    }
    case types.SET_CURRENT_LINK: {
      return {
        ...state,
        player: {
          ...state.player,
          currentLink: action.payload.url,
          isRestrictions: action.payload.isRestrictions,
        },
      };
    }
    case types.UPDATE_CURATED_LIVE: {
      return {
        ...state,
        player: {
          ...state.player,
          curatedLive: action.payload,
        },
      };
    }
    case types.UPDATE_TOKEN_DRM: {
      let data = state.player.drm;
      if (action.value.remove) {
        const indexRemove = data.findIndex(
          content => content.slug === action.value.slug && content.remove !== action.value.remove,
        );
        if (indexRemove >= 0) {
          data.splice(indexRemove, 1);
        }
      }
      const index = data.findIndex(content => content.slug === action.value.slug);
      if (index >= 0) {
        const drm = data[index];
        drm.token = action.value.token;
        drm.time = action.value.time;
      } else if (!action.value.remove) {
        data = [...data, action.value];
      }
      return {
        ...state,
        player: {
          ...state.player,
          drm: data,
        },
      };
    }
    default:
      return state;
  }
}
