import FETCH from '../utils/fetch';
import * as APIString from '../constants/apiString';
import { store } from '../store/index';

function getMuxEnvKey() {
  const { account } = store.getState().auth;
  // if (!account) {
  //   return Promise.resolve(null)
  // }
  const authorization = account && account.access_token ? account.access_token : '';
  const path = `/tenants/${APIString.TENANT_SLUG}/configs/mux_env_key/`;

  return FETCH(path, 'GET', {
    authorization,
    'Content-Type': 'application/json',
  });
}

function getRegionSubscription() {
  let path = `/tenants/${APIString.TENANT_SLUG}/configs/check_available_region_for_subscription/`;
  return FETCH(path, 'GET', {});
}

export { getMuxEnvKey, getRegionSubscription };
