import * as types from './actionTypes';

function initialApp() {
  return {
    type: types.INITIAL_APP,
  };
}

function loadingLocalStogare() {
  return {
    type: types.LOADING_LOCAL_STORAGE,
  };
}

function authencate(isAuth) {
  return {
    type: types.AUTHENTICATE,
    isAuth,
  };
}

function changeLanguage(value) {
  return {
    type: types.CHANGE_LANGUAGE,
    language: value,
  };
}
function isBlockCountries(value) {
  return {
    type: types.COUNTRIES_BLOCK,
    isBlock: value,
  };
}
function updateTenantSlug(value) {
  return {
    type: types.UPDATE_TENANT_SLUG,
    tenantSlug: value,
  };
}

function updateMuxKey(value) {
  return {
    type: types.UPDATE_MUX_KEY,
    muxKey: value,
  };
}

function updateRegionSubscription(value) {
  return {
    type: types.UPDATE_REGION_SUBCRIPTION,
    isFollowSubscription: value,
  };
}

export {
  initialApp,
  loadingLocalStogare,
  authencate,
  changeLanguage,
  isBlockCountries,
  updateTenantSlug,
  updateMuxKey,
  updateRegionSubscription,
};
