import * as actions from './actions';
import * as appAPIs from '../../api/appAPIs';
import * as planApi from '../../api/plan';
import * as subscriptionApi from '../../api/subscription';
import dayjs from 'dayjs';

export {
  changePassword,
  getProfile,
  changeProfile,
  getPaymentPlan,
  getClientSecret,
  refresh_stb_auth_code,
  paymentPlan,
  get_actived_subscription_plan,
  register_or_login,
  paymentPlatTotol,
  resend_email,
  loginEmail,
  registerEmail,
  updateDataPlanSelected,
  resetDataFormPlanSelected,
  getPlans,
  updateDataBoxSelect,
  resetDataBoxSelected,
  postStripSubscriptions,
  updateAddress,
  resetAddress,
  loading,
  supportsContactUs,
  createSubscriptionPaypal,
  confirmPaypalSubscription,
};

function getProfile() {
  return dispatch => {
    return new Promise((resolve, reject) => {
      appAPIs
        .getProfile()
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  };
}

function changePassword(old_password, password) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      appAPIs
        .changePassword(old_password, password)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  };
}

function changeProfile(firstName, lastName, phoneNumber, gender, birthday) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      appAPIs
        .updateProfile(firstName, lastName, phoneNumber, gender, birthday)
        .then(response => {
          resolve(response);
          dispatch(actions.changeProfileSuccess(response));
        })
        .catch(error => {
          reject(error);
        });
    });
  };
}

function getPaymentPlan() {
  return dispatch => {
    return new Promise((resolve, reject) => {
      appAPIs
        .get_payment_plan()
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  };
}

function getClientSecret(plan_id) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      appAPIs
        .getClientSecret(plan_id)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  };
}

function refresh_stb_auth_code() {
  return dispatch => {
    return new Promise((resolve, reject) => {
      appAPIs
        .refresh_stb_auth_code()
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  };
}

function paymentPlan(stripe_plan_id, stripe_payment_method_id, product) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      appAPIs
        .paymentPlan(stripe_plan_id, stripe_payment_method_id, product)
        .then(response => {
          if (product) {
            appAPIs.updateProfile(
              product.received_address.customer_name,
              '',
              '',
              0,
              dayjs().format('YYYY-MM-DD'),
            );
            dispatch(actions.updateFirstName(product.received_address.customer_name));
          }
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  };
}

function get_actived_subscription_plan() {
  return dispatch => {
    return new Promise((resolve, reject) => {
      appAPIs
        .get_actived_subscription_plan()
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  };
}

function register_or_login(email, password) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      appAPIs
        .register_or_login(email, password)
        .then(response => {
          dispatch(actions.registerLogin(response));
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  };
}

function registerEmail(email, password) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      appAPIs
        .registerEmail(email, password)
        .then(response => {
          // dispatch(actions.registerLogin(response))
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  };
}

function resend_email(type) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      appAPIs
        .resend_email(type)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  };
}

function paymentPlatTotol(stripe_plan_id, product) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      appAPIs
        .paymentPlatTotol(stripe_plan_id, product)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  };
}

function loginEmail(email, password) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      appAPIs
        .login_email(email, password)
        .then(response => {
          dispatch(actions.registerLogin(response));
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  };
}

const updateDataPlanSelected = payload => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch(actions.updateDataPlanSelected(payload));
      resolve();
    });
  };
};

const resetDataFormPlanSelected = () => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch(actions.resetDataPlanSelected());
      resolve();
    });
  };
};
const updateDataBoxSelect = payload => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch(actions.updateDataBoxSelect(payload));
      resolve();
    });
  };
};

const resetDataBoxSelected = payload => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch(actions.resetDataBoxSelect(payload));
      resolve();
    });
  };
};

const updateAddress = payload => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch(actions.updateAddress(payload));
      resolve();
    });
  };
};

const resetAddress = () => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch(actions.resetAddress());
      resolve();
    });
  };
};

const loading = payload => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch(actions.loading(payload));
      resolve();
    });
  };
};

const getPlans = (slug = null) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      planApi
        .getPlans(slug)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  };
};

const postStripSubscriptions = data => {
  const { draft } = data;
  return dispatch => {
    return new Promise((resolve, reject) => {
      subscriptionApi
        .postSubscriptions(data)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  };
};

function supportsContactUs(data) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      appAPIs
        .supports(data)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  };
}

function createSubscriptionPaypal(data) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      subscriptionApi
        .createPaypalSubscription(data)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  };
}

function confirmPaypalSubscription(data) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      subscriptionApi
        .confirmPaypalSubscription(data)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  };
}

export const smsOTPCreate = params => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      subscriptionApi
        .smsOTPCreate(params)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  };
};

export const smsPaymentConfirm = params => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      subscriptionApi
        .smsPaymentConfirm(params)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  };
};

export const setPreviousLocation = payload => {
  return dispatch => {
    const pathname = (payload && payload.pathname) || '';
    if (pathname && pathname.indexOf('/box/') > -1) {
      return;
    }
    dispatch(actions.setPreviousLocation(payload));
  };
};

export const setTransactionId = payload => {
  return dispatch => {
    dispatch(actions.setTransactionId(payload));
  };
};

export const pgMomoCreate = params => {
  return subscriptionApi.pgMomoCreate(params);
};

export const pgMomoConfirm = params => {
  return subscriptionApi.pgMomoConfirm(params);
};
export const pgVNPayCreate = params => {
  return subscriptionApi.pgVNPayCreate(params);
};

export const pgVNPayConfirm = params => {
  return subscriptionApi.pgVNPayConfirm(params);
};

export const setTotal = payload => {
  return dispatch => {
    dispatch(actions.setTotal(payload));
  };
};

export const subscriptionCalc = data => {
  return dispatch => {
    dispatch(actions.loading(true));

    return new Promise((resolve, reject) => {
      subscriptionApi
        .subscriptionCalc(data)
        .then(res => {
          dispatch(actions.setCalculations(res));
          resolve(res);
        })
        .catch(reject)
        .finally(() => {
          dispatch(actions.loading(false));
        });
    });
  };
};

export const bankPGCreate = params => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      subscriptionApi
        .bankPGCreate(params)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  };
};
