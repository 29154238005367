import React from 'react';
import { App } from './app/views';
import AlertPopup from '~components/alertPopup/AlertPopup';
import { useSelector } from 'react-redux';

export default props => {
  const account = useSelector(state => state.auth.account);
  return (
    <App {...props} key={account?.profile?.id}>
      <AlertPopup />
    </App>
  );
};
