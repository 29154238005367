import * as types from './actionTypes';

export { changeProfileSuccess, changeEmail, getProfile, updateHasSupscription };

function changeProfileSuccess(account) {
  return {
    type: types.UPDATE_PROFILE_SUCCESS,
    account,
  };
}

function changeEmail(email) {
  return {
    type: types.UPDATE_EMAIL,
    email,
  };
}

function getProfile(account) {
  return {
    type: types.GET_PROFILE,
    account,
  };
}

function updateHasSupscription(account) {
  return {
    type: types.UPDATE_ACCOUNT_SUPSCRIPTION,
    account,
  };
}
