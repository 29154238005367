import * as types from './actionTypes';

export {
  loginSuccess,
  resetPassSuccess,
  resetPassFailure,
  RequestCodeSuccess,
  RequestCodeFailure,
  logout,
  updateAccountPaymentMethod,
  updateIP,
  setIsShowLogin,
  updateHasSupscription,
  updateAccountPaymentMethodPaypal,
  setPaidContents,
  resetPaymentMethod,
};

function loginSuccess(account) {
  return {
    type: types.LOGIN_SUCCESS,
    account,
  };
}

function resetPassSuccess(reset) {
  return {
    type: types.RESETPASS_SUCCESS,
    reset,
  };
}

function resetPassFailure() {
  return {
    type: types.RESETPASS_FAILURE,
  };
}

function RequestCodeSuccess(otp) {
  return {
    type: types.REQUESTCODE_SUCCESS,
    otp,
  };
}

function RequestCodeFailure(error) {
  return {
    type: types.REQUESTCODE_FAILURE,
    error,
  };
}

function logout() {
  return {
    type: types.SIGNOUT_SUCCESS,
  };
}

function updateAccountPaymentMethod(payload) {
  return {
    type: types.UPDATE_ACCOUNT_PAYMENT_METHOD,
    payload,
  };
}
function updateAccountPaymentMethodPaypal(payload) {
  return {
    type: types.UPDATE_ACCOUNT_PAYMENT_METHOD_PAYPAL,
    payload,
  };
}

function updateIP(payload) {
  return {
    type: types.UPDATE_IP,
    payload,
  };
}

function setIsShowLogin(payload) {
  return {
    type: types.SET_IS_SHOW_LOGIN,
    payload,
  };
}
function updateHasSupscription(account) {
  return {
    type: types.UPDATE_ACCOUNT_SUPSCRIPTION,
    account,
  };
}

function setPaidContents(payload) {
  return {
    type: types.SET_PAID_CONTENT,
    payload,
  };
}

function resetPaymentMethod(account) {
  return {
    type: types.RESET_PAYMENT_METHOD,
    account,
  };
}
