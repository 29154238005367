import React from 'react';
import { render, hydrate } from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from './store';
// import { App } from './app/views/index'
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n/index';
import { history } from './store/history';
import { Router } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import * as APIString from './constants/apiString';
import * as Version from './constants/envConfig';
import App from './App';
import { ThemeProvider } from 'styled-components';
import GlobalStyles from './features/theme/globalStyles';
import { detectTheme } from './features/theme/detectTheme';
import { getConfigByKey } from './features/tenantConfig/services';
import { isNode } from '~utils/ssr';

const environment = APIString.REACT_APP_ENV;

Sentry.init({
  dsn: 'https://4ca7335d46ed4935b7c20c152a10dff5@o166441.ingest.sentry.io/5870387',
  environment,
  release: Version.VERSION,
});

export const provideComponent = function (Component, element) {
  const reactMountFn = element.childElementCount === 0 ? render : hydrate;

  reactMountFn(
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Router history={history}>
          <I18nextProvider i18n={i18n}>
            <ThemeProvider theme={detectTheme(getConfigByKey('tenant_slug'))}>
              <GlobalStyles />
              {React.cloneElement(Component)}
            </ThemeProvider>
          </I18nextProvider>
        </Router>
      </PersistGate>
    </Provider>,
    element,
  );
};

const runApp = () => {
  if (isNode()) {
    return;
  }
  const mountElement = document.getElementById('root');
  window.onload = () => {
    provideComponent(
      <>
        <GlobalStyles />
        <App />
      </>,
      mountElement,
    );
  };
};

runApp();
