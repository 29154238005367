import * as actions from './actions';
import * as appAPIs from '../../api/appAPIs';
import * as auth from '../../api/auth';
import * as constants from './constants';
import * as referrals from '../../services/referrals';
import * as filterActions from '../../components/filter/filterSlice';
import { setUserProperties } from './../../services/analytics/SetUserProperties';
import { getConfigByKey } from '../tenantConfig/services';

export {
  loginEmail,
  requestCodeOtp,
  resetPasswordEmail,
  logout,
  registerEmail,
  registerFB,
  getAccountPaymentMethod,
  updateAccountPaymentMethod,
  loginSSO,
  setIsShowLogin,
  getPaidContent,
  resetPaymentMethod,
  registerEmail_SCTV,
  loginPhoneNumberSCTV,
};

let isLoggingOut = false;

function loginEmail(email, password) {
  const loginMethod = getConfigByKey('features.loginPhoneNumber')
    ? appAPIs.loginPhoneNumber
    : appAPIs.login_email;
  return dispatch => {
    return new Promise((resolve, reject) => {
      loginMethod(email, password)
        .then(response => {
          dispatch(actions.loginSuccess(response));
          setUserProperties();
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  };
}

function loginPhoneNumberSCTV(phone, password) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      appAPIs
        .loginPhoneNumber(phone, password)
        .then(response => {
          dispatch(actions.loginSuccess(response));
          setUserProperties();
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  };
}

function requestCodeOtp(email, url, phoneNumber) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      appAPIs
        .requestCode(email, url, phoneNumber)
        .then(response => {
          // dispatch(actions.RequestCodeSuccess(response))
          resolve(response);
        })
        .catch(error => {
          dispatch(actions.RequestCodeFailure(error));
          reject(error);
        });
    });
  };
}

function resetPasswordEmail(email, otp, new_password) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      appAPIs
        .resetPassword(email, otp, new_password)
        .then(response => {
          if (response) {
            dispatch(actions.resetPassSuccess(response));
            resolve(response);
          } else {
            dispatch(actions.resetPassFailure());
            reject();
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  };
}

function logout() {
  return dispatch => {
    if (isLoggingOut) {
      return Promise.reject('This account is logging out.');
    }
    isLoggingOut = true;

    return new Promise(async (resolve, reject) => {
      try {
        await auth.logout();
        dispatch(filterActions.resetActiveIDs());
        dispatch(actions.logout());
        resolve(true);
        setTimeout(() => {
          setUserProperties();
        }, 2000);
      } catch (error) {
        reject(error);
      }
      isLoggingOut = false;
    });
  };
}

function registerEmail(
  email,
  firstName,
  lastName,
  phoneNumber,
  password,
  gender,
  birthday,
  register_type,
) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      appAPIs
        .register_email(
          email,
          firstName,
          lastName,
          phoneNumber,
          password,
          gender,
          birthday,
          register_type,
        )
        .then(response => {
          referrals.registerEvent({ email: email, orderID: email });
          setUserProperties();
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  };
}

function registerEmail_SCTV(params) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      appAPIs
        .register_emaill_SCTV(params)
        .then(response => {
          referrals.registerEvent({
            email: params.email,
            orderID: params.email,
          });
          setUserProperties();
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  };
}

function registerFB(token, platform, model, device_id, mac_address) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      appAPIs
        .fbLogin(token, platform, model, device_id, mac_address)
        .then(response => {
          const { email, id } = response.profile;
          referrals.registerEvent({ email: email, orderID: email });
          setUserProperties();
          dispatch(actions.loginSuccess(response));
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  };
}

function getAccountPaymentMethod() {
  return dispatch => {
    return new Promise((resolve, reject) => {
      appAPIs
        .getAccountPaymentMethod()
        .then(response => {
          dispatch(actions.updateAccountPaymentMethod(response));
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  };
}
function updateAccountPaymentMethod(payload) {
  return dispatch => {
    dispatch(actions.updateAccountPaymentMethod(payload));
  };
}

function loginSSO(data = {}) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      auth
        .loginSSO(data)
        .then(response => {
          const payload = {
            ...response,
            methodLogin: constants.METHOD_LOGIN_SSO,
          };
          dispatch(actions.loginSuccess(payload));
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  };
}

function setIsShowLogin(payload = false) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch(actions.setIsShowLogin(payload));
      resolve(payload);
    });
  };
}

function getPaidContent() {
  return dispatch => {
    return new Promise((resolve, reject) => {
      auth
        .getPaidContent()
        .then(response => {
          dispatch(actions.setPaidContents(response));
          resolve(response);
        })
        .catch(errors => {
          reject(errors);
        });
    });
  };
}

function resetPaymentMethod() {
  return dispatch => {
    dispatch(actions.resetPaymentMethod());
  };
}
