import * as actionTypes from './actionTypes';
const initLoading = {
  isLoading: false,
};

const loading = (state = initLoading, action) => {
  switch (action.type) {
    case actionTypes.IS_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    default:
      return state;
  }
};
export default loading;
